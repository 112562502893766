import { Navigate, useLocation } from "react-router-dom";
import { ScaleOps, ScaleOpsClient } from "../api/api";
import { components } from "../api/schema";
import { SetConfig } from "../utils/ConfigHelper";
import { getConfig, GetConfigResponse } from "../api/fetcher";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import LoadingPage from "../LoadingPage";
import ErrorPage from "../ErrorPage";
import { ApiError } from "openapi-typescript-fetch";

interface PrivateRouteProps {
  children: JSX.Element;
}

export default function PrivateRoute({ children }: PrivateRouteProps): JSX.Element {
  const api: ScaleOpsClient = ScaleOps();
  const location = useLocation();
  const configApi = getConfig();
  const { data, isLoading, isError, error } = useQuery<GetConfigResponse, ApiError>({
    queryKey: [configApi.queryKey],
    queryFn: configApi.queryFn,
  });
  const [config, setConfig] = useState<components["schemas"]["ConfGetConfResponse"] | undefined>(undefined);

  useEffect(() => {
    if (data !== undefined) {
      setConfig(data);
      SetConfig(data);
    }
  }, [data]);
  if (isLoading && !config) {
    return <LoadingPage />;
  }
  if (isError && !config) {
    return <ErrorPage apiError={error} />;
  }
  if (config === undefined) {
    return <></>;
  }
  function redirectLogin() {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!api.loggedIn && config?.getAuthFromUser) {
    return redirectLogin();
  }

  return children;
}
