import { Typography } from "@mui/material";
import clsx from "clsx";
import { toast } from "react-toastify";
import DuplicateIcon from "../Icons/DuplicateIcon";
import { TOAST_SETTINGS } from "../pages/Roles/mutations/utils";
import Tooltip from "./Tooltip";

const ICON_WIDTH = 12;

interface Props {
  color: string;
  value: React.ReactNode;
  label: React.ReactNode;
  secondaryValue?: React.ReactNode;
  isDashed?: boolean;
  className?: string;
  enableCopyTextOnClick?: string;
  customPrefix?: string;
  hasLimitedWidth?: boolean;
  tooltip?: React.ReactNode;
}

const ChartTooltipElement = ({
  label,
  secondaryValue,
  value,
  color,
  isDashed,
  className,
  enableCopyTextOnClick,
  customPrefix,
  hasLimitedWidth,
  tooltip,
}: Props) => (
  <div className={clsx(className, "flex gap-2 items-center text-[10px]")}>
    <div className="relative h-[4px] w-[24px]">
      <div className="h-full w-full rounded-lg" style={{ background: color }} />
      {isDashed && (
        <div className="absolute w-full top-0 right-0 flex justify-evenly">
          {[...new Array<number>(3)].map((_, index) => (
            <div key={index} className="h-[4px] w-[10%] bg-white" />
          ))}
        </div>
      )}
    </div>
    {enableCopyTextOnClick && (
      <DuplicateIcon
        className="cursor-pointer"
        width={ICON_WIDTH}
        height={ICON_WIDTH}
        onClick={(e) => {
          if (enableCopyTextOnClick && label) {
            navigator.clipboard.writeText(enableCopyTextOnClick);
            e.stopPropagation();
            e.preventDefault();
            toast.success("Value copied to clipboard", TOAST_SETTINGS);
          }
        }}
      />
    )}
    <Tooltip
      className={clsx("flex justify-between w-full truncate", {
        "max-w-[140px]": hasLimitedWidth,
      })}
      title={<Typography variant="caption">{tooltip ?? customPrefix ?? label}</Typography>}
      maxWidth={800}
    >
      <span className="flex truncate">{customPrefix ?? <span className="truncate">{label}</span>}</span>
      <span className="pl-5">
        {value} {secondaryValue}
      </span>
    </Tooltip>
  </div>
);

export default ChartTooltipElement;
