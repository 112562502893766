import clsx from "clsx";
import { TooltipProps } from "recharts";
import ChartTooltipElement from "../../../../components/ChartTooltipElement";
import ChartTooltipTime from "../../../../components/ChartTooltipTime";
import RightArrowIcon from "../../../../Icons/RightArrowIcon";
import { DEFAULT_DATE_TIME_FORMAT, getMemoryValue } from "../../../../utils/formatterUtils";
import { TOOLTIP_WRAPPER_CLASS_NAME } from "../../../../utils/styleUtils";
import { Event, EventChartPoint, EventsDisplayName, GraphElements } from "./utils";

const RightArrow = () => <RightArrowIcon width={10} height={10} />;

type Props = TooltipProps<number, string> & {
  data: EventChartPoint[];
  selectedChartComponents: Event[];
};

const CustomTooltip = ({ payload, data, selectedChartComponents }: Props) => {
  if (payload && payload.length) {
    const mainPayloadData = payload[0].payload as EventChartPoint;
    const { timestamp } = mainPayloadData as { timestamp: number };
    const originalData = data.find((d) => d.timestamp === timestamp);

    // Pod optimized
    const podsOptimizedLabel = EventsDisplayName.podOptimizedCount;
    const podsOptimizedColor = GraphElements[Event.podOptimizedCount].color;
    const podsOptimizedColorCpuBefore = originalData?.data?.podOptimized?.cpuBefore ?? 0;
    const podsOptimizedColorCpuAfter = originalData?.data?.podOptimized?.cpuAfter ?? 0;
    const podsOptimizedColorMemoryBefore = getMemoryValue(
      (originalData?.data?.podOptimized?.memoryBefore ?? 0) * 1024 * 1024 * 1024
    );
    const podsOptimizedColorMemoryAfter = getMemoryValue(
      (originalData?.data?.podOptimized?.memoryAfter ?? 0) * 1024 * 1024 * 1024
    );

    // Pod evication
    const podEvictionLabel = EventsDisplayName.podEvictionCount;
    const podEvictionValue = originalData?.podEvictionCount ?? 0;

    // optimizationBlockedCount
    const optimizationBlockedLabel = EventsDisplayName.optimizationBlockedCount;
    const optimizationBlockedValue = originalData?.data?.optimizationBlocked?.message ?? "";

    return (
      <div className={clsx("bg-[rgba(255,255,255,0.9)] pointer-events-auto", TOOLTIP_WRAPPER_CLASS_NAME)}>
        <ChartTooltipTime timestamp={Number(timestamp ?? 0) * 1000} timeFormat={DEFAULT_DATE_TIME_FORMAT} />
        {selectedChartComponents.includes(Event.podOptimizedCount) && mainPayloadData.podOptimizedCount !== undefined && (
          <ChartTooltipElement
            label={podsOptimizedLabel}
            value={
              <div className="flex items-center gap-1">
                <span>CPU:</span>
                <span>{podsOptimizedColorCpuBefore}</span>
                <RightArrow />
                <span>{podsOptimizedColorCpuAfter}</span>
                <span>|</span>
                <span>Memory:</span>
                <span>{podsOptimizedColorMemoryBefore}</span>
                <RightArrow />
                <span>{podsOptimizedColorMemoryAfter}</span>
                <span>|</span>
                <span>Count:</span>
                <span>{mainPayloadData.data?.podOptimizedCount}</span>
              </div>
            }
            color={podsOptimizedColor}
          />
        )}
        {selectedChartComponents.includes(Event.podEvictionCount) && mainPayloadData.podEvictionCount !== undefined && (
          <ChartTooltipElement
            label={podEvictionLabel}
            value={podEvictionValue}
            color={GraphElements[Event.podEvictionCount].color}
          />
        )}
        {selectedChartComponents.includes(Event.isAuto) && mainPayloadData.isAuto !== undefined && (
          <ChartTooltipElement
            label={EventsDisplayName.isAuto}
            value={mainPayloadData.isAuto === 1 ? 1 : undefined}
            color={GraphElements[Event.isAuto].color}
          />
        )}
        {selectedChartComponents.includes(Event.optimizationBlockedCount) &&
          mainPayloadData.optimizationBlockedCount !== undefined && (
            <div className="text-[10px] w-full">
              <div className="flex gap-2 items-center w-full">
                <div
                  className="h-[4px] w-[24px] rounded-lg"
                  style={{ background: GraphElements[Event.optimizationBlockedCount].color }}
                />
                <p>{optimizationBlockedLabel}</p>
              </div>
              <p className="max-w-[250px] ml-[32px] italic">{optimizationBlockedValue}</p>
            </div>
          )}
      </div>
    );
  }
  return null;
};

export default CustomTooltip;
