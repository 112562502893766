import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { GetScheduleBlockersGraph, GetScheduleBlockersGraphResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";
import { PAGE_CONTENT_CLASS_NAME } from "../../../utils/styleUtils";
import { getDisplayName } from "../../autoscalers/ScheduleBlockersGraph/utils";
import BlockedNodesFilters from "./BlockedNodesFilters";
import BlockedNodesTable from "./BlockedNodesTable";

const { queryKey, queryFn } = GetScheduleBlockersGraph();

const BlockedNodesTableContainer = () => {
  const [graphData, setGraphData] = useState<
    | (components["schemas"]["NodeGroupsBlockedNodeTableEntry"] & { id: string; action: string | undefined })[]
    | undefined
  >([]);

  const [searchTerm] = useQueryParam("blockedNodesSearchTerm", StringParam);

  const { data, isError, isLoading } = useQuery<GetScheduleBlockersGraphResponse, Error>({
    queryKey: [queryKey],
    queryFn: () => queryFn(),
  });

  useEffect(() => {
    let newGraphData = data?.tableData
      ?.map((entry) => ({
        ...entry,
        id: String(entry.node),
        action: entry.blockedReason,
      }))
      ?.filter((entry) => {
        if (!searchTerm) {
          return true;
        }

        const search = searchTerm.toLowerCase().trim();
        const blockedReason = getDisplayName(entry?.blockedReason ?? "");

        return entry.node?.toLowerCase().includes(search) || blockedReason?.toLowerCase().includes(search);
      });

    if (newGraphData) {
      newGraphData = newGraphData.sort((a, b) => {
        if (Number(a.savingsAvailable) < Number(b.savingsAvailable)) {
          return 1;
        } else if (Number(a.savingsAvailable) > Number(b.savingsAvailable)) {
          return -1;
        }
        return 0;
      });
    }

    setGraphData(newGraphData);
  }, [data, searchTerm]);

  if (isError) {
    console.log("Error fetching schedule blockers graph data");
    return null;
  }

  return (
    <div className={clsx("flex flex-col gap-4", PAGE_CONTENT_CLASS_NAME)}>
      <Typography variant="body1" fontWeight={700}>
        Blocked nodes
      </Typography>
      <BlockedNodesFilters />
      <BlockedNodesTable data={graphData} isLoading={isLoading} />
    </div>
  );
};

export default BlockedNodesTableContainer;
