import { SCALEOPS_COLORS } from "../../../colors";
import { camelCaseToSnakeSpace } from "../../../utils/formatterUtils";

export const ROW_DATA = "rowData";
export const SCHEDULE_BLOCKER = "ScheduleBlocker";

export type GraphPoint = Record<
  string,
  | string
  | number
  | {
      [key: string]:
        | {
            count?: number;
            nodes?: string[];
          }
        | undefined;
    }
  | undefined
>;

export enum ScaleDownBlockers {
  PdbTemporaryBlockingScaleDown = "UnevictableWorkloadDueToPDB",
  PdbPreventingScaleDown = "UnevictableWorkloadDueToPDB",
  PodWithNoOwner = "UnevictablePodWithoutOwner",
  LocalStorageRequested = "LocalStorageRequested",
  NotSafeToEvictAnnotation = "UnevictableWorkloadDueToAnnotation",
  UnmovableKubeSystemPod = "UnevictableKubeSystemWorkloads",
  MinReplicasReached = "MinReplicasReached",
  PodOwnerReachedToMinimumReplicas = "PodOwnerReachedToMinimumReplicas",

  // Scheduler Blockers
  DynamicResources = "InsufficientAllocationForDynamicResources",
  VolumeBinding = "UnsatisfiedPvcRequirements",
  VolumeRestrictions = "UnsatisfiedPvcVolumeRestrictions",
  VolumeZone = "UnsatisfiedPvcZoneRequirements",
  Affinity = "UnsatisfiedPodAffinity",
  AntiAffinity = "UnsatisfiedPodAntiAffinity",
  NodeAffinity = "UnsatisfiedNodeAffinity",
  NodeSelector = "UnsatisfiedNodeSelector",
  NodeName = "UnsatisfiedNodeName",
  NodePorts = "UnsatisfiedAvailablePorts",
  NodeResources = "InsufficientAllocationForResourceRequests",
  NodeUnschedulable = "NodeWithAnUnschedulableTaint",
  PodTopologySpread = "TopologySpreadConstraints",
  TaintToleration = "NodeWithAnUntoleratedTaint",

  // Karpenter Blockers
  MandatoryNodeLabelsDoesNotExist = "MissingKarpenterNodeLabels",
  BlockingAnnotationsInNodeLevel = "KarpenterAnnotationConstraint",
  InvalidNodePool = "InvalidNodePool",
  DisruptionBlocked = "KarpenterDisruptionBlocked",
  DoNotConsolidateAnnotationOnNode = "KarpenterAnnotationConstraint",
  ProvisionerDoesNotExists = "MissingProvisioner",
  ProvisionerConsolidateDisabled = "ProvisionerConsolidateDisabled",
  EmptinessTimestampDoesntExist = "EmptinessTimestampMissing",
  FailedToParseEmptiness = "UnexpectedEmptinessStructure",
  NotEmptyForEnoughTime = "RequiredEmptinessDuration",
  NodePoolPolicyWhenEmpty = "WhenEmptyNodePoolConfiguration",
  NodePoolDoesNotExists = "MissingNodePool",
  NodePoolConsolidationDisabled = "NodePoolConsolidationDisabled",
  NodePoolBudgetExceeded = "NodePoolBudgetConstraint",

  // ClusterAutoScaler Blockers
  NotUnderutilized = "NodeOverUtilized",
  ScalingDownAllocationThresholdIsTooLow = "ScalingDownAllocationThresholdTooLow",
  ScaleDownDisabledAnnotation = "ClusterAutoScalerAnnotationConstraint",
  NodeGroupMinSizeReached = "NodeGroupMinSizeConstraint",
  NotUnreadyLongEnough = "NotUnreadyLongEnough",

  // Node Deletion Blockers
  CurrentlyBeingDeleted = "DeletionInProgress",
  NodePendingDeletion = "NodePendingRemoval",

  NoReason = "NoReason",
  UnexpectedError = "UnexpectedError",
  NodeNotSafeToEvictLabel = "ScalerLabelConstraint",
  NodeNotSafeToEvictAnnotation = "ScalerAnnotationConstraint",
  HighMemoryUtilization = "NodeHighAllocation",
  HighCpuUtilization = "NodeHighAllocation",
  HighCpuAndMemoryUtilization = "NodeHighAllocation",
  UninitializedNode = "UninitializedNode",
}

export const CHART_COLORS = SCALEOPS_COLORS.blockedReason;

export const getDisplayName = (value: string) => {
  value = value.replaceAll("-", "");
  return camelCaseToSnakeSpace(ScaleDownBlockers[value as keyof typeof ScaleDownBlockers] ?? value);
};
