import { getDisplayWorkloadName } from "./namesUtils";
import { getWorkloadType, getWorkloadTypeDisplayValue } from "./typesUtils";

interface Props {
  namespace?: string;
  workloadName?: string;
  type?: string;
  cluster?: string;
}
const keyValRender = (key: string, value?: string) =>
  value && (
    <div>
      <b>{key}:</b> {value}
    </div>
  );

const WorkloadTooltip = ({ namespace, workloadName, type, cluster }: Props) => {
  type = getWorkloadType(workloadName, type);
  workloadName = getDisplayWorkloadName(workloadName ?? "");
  type = getWorkloadType(workloadName, type);
  return (
    <div className={"flex flex-col gap-0.5"}>
      {namespace && keyValRender("Namespace", namespace)}
      {keyValRender("Name", workloadName)}
      {type && keyValRender("Type", getWorkloadTypeDisplayValue(type)?.toLowerCase())}
      {cluster && keyValRender("Cluster", cluster)}
    </div>
  );
};

export default WorkloadTooltip;
