import React, { useEffect } from "react";
import { components } from "../../../api/schema";
import Button, { BUTTON_VARIANTS } from "../../Button";
import useGetNodeAction from "./useGetNodeAction";
import { BlockedNodeReasons } from "./utils";

const BUTTON_CLASS_NAME = "w-[240px]";

interface Props {
  params: components["schemas"]["NodeGroupsBlockedNodeTableEntry"];
}

const NodeAction = ({ params }: Props) => {
  const nodeAction = useGetNodeAction();
  const [content, setContent] = React.useState<React.ReactNode | null>(null);

  useEffect(() => {
    switch (params.category) {
      case BlockedNodeReasons.OptimizeUnevictablePods:
        setContent(
          <Button
            onClick={() => nodeAction[BlockedNodeReasons.OptimizeUnevictablePods]({})}
            label={"Optimize unevictable pods"}
            variant={BUTTON_VARIANTS.regular}
            className={BUTTON_CLASS_NAME}
          />
        );
        break;
      case BlockedNodeReasons.ExploreSchedulingReasons:
        setContent(
          <Button
            onClick={() =>
              nodeAction[BlockedNodeReasons.ExploreSchedulingReasons]({
                schedulerBlocker: params.blockedReason,
              })
            }
            label={"Explore workloads"}
            variant={BUTTON_VARIANTS.regular}
            className={BUTTON_CLASS_NAME}
          />
        );
        break;
      case BlockedNodeReasons.ChangeClusterAutoScalerConfiguration:
        setContent(
          <Button
            onClick={() => nodeAction[BlockedNodeReasons.ChangeClusterAutoScalerConfiguration]({})}
            label={"Change cluster configuration"}
            variant={BUTTON_VARIANTS.regular}
            className={BUTTON_CLASS_NAME}
          />
        );
        break;
      case BlockedNodeReasons.ExploreNodeRestriction:
        setContent(
          <Button
            onClick={() => nodeAction[BlockedNodeReasons.ExploreNodeRestriction]({ node: params.node })}
            label={"Explore node YAML"}
            variant={BUTTON_VARIANTS.regular}
            className={BUTTON_CLASS_NAME}
          />
        );
        break;
      default:
        setContent(null);
    }
  }, [params.blockedReason]);

  return <div>{content}</div>;
};

export default NodeAction;
