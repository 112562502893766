export const defaultPolicyParams = {
  kind: "Policy",
  apiVersion: "analysis.scaleops.sh/v1alpha1",
  metadata: {},
  spec: {
    type: "Optimize",
    completionStrategy: {
      periodSeconds: 180,
      completionStrategyType: "Periodical",
    },
    policyOptimize: {
      rightSizePolicy: {
        window: "48h",
        windowByResource: {
          cpu: "24h",
          memory: "24h",
        },
        requestsConfigs: {
          cpu: {
            name: "168h",
            headroomPercentage: 5,
            percentilePercentage: 90,
            minAllowed: "1m",
          },
          memory: {
            name: "168h",
            headroomPercentage: 5,
            percentilePercentage: 90,
          },
        },
        limitConfigs: {
          cpu: {
            name: "168h",
            noLimit: true,
            keepLimit: false,
            equalsToRequest: false,
            percentilePercentage: 99,
            headroomPercentage: 40,
          },
          memory: {
            name: "168h",
            noLimit: false,
            keepLimit: true,
            equalsToRequest: false,
            percentilePercentage: 99,
            headroomPercentage: 40,
          },
        },
        costReductionRecommendationOnly: {
          cpu: false,
          memory: false,
        },
        hpaUtilTriggerRightsizeProtection: true,
      },
      priority: {
        name: "",
      },
      scaleOutPolicy: {
        scaleOutBehavior: {
          scaelUpStabilizationWindowSeconds: 300,
          scaelDownStabilizationWindowSeconds: 300,
        },
        hpaMetrics: undefined,
        metricGoals: undefined,
      },
    },
    updatePolicy: {
      updateMode: "Ongoing",
      minReplicas: 1,
      stabilizationWindowSeconds: {
        scaleDown: 3600,
        scaleUp: 360,
      },
      evictionSchedule: {
        scaleUp: "* * * * *",
        scaleDown: "* * * * *",
      },
      maxUnavailablePodsPercentage: 10,
      podMinReadySeconds: 5,
      minScaleUpChangeDiff: {
        cpu: 0.1,
        memory: 0.1,
      },
      minScaleDownChangeDiff: {
        cpu: 0.1,
        memory: 0.1,
      },
      bootTime: false,
      requiredWindowCoveragePercentage: 0,
      allowRollingUpdate: true,
      binPackUnEvictablePods: true,
    },
    hpa: {
      manageHPA: true,
    },
  },
};
