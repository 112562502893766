import { TOOLTIP_WRAPPER_CLASS_NAME } from "../../../utils/styleUtils";

export type XAxisTooltip = { value: string | number; coordinate: number; index: number } | null;

const AxisTooltip = ({ tooltip }: { tooltip: XAxisTooltip }) => {
  if (tooltip?.index === undefined) return null;

  return (
    <div
      style={{
        position: "absolute",
        left: tooltip.coordinate - 50,
        bottom: 50,
        width: 100,
        height: "fit-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "visible",
        whiteSpace: "nowrap",
        zIndex: 999,
      }}
    >
      <div className={TOOLTIP_WRAPPER_CLASS_NAME}>{tooltip.value}</div>
    </div>
  );
};

export default AxisTooltip;
