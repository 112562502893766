import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMainContext } from "../MainContext";
import { HAS_MULTILEVEL_ANALYTICS } from "../pages/Analytics/AnalyticsV2/AnalyticsContainer";
import MainMenuItem, { MainMenuItemSize } from "./MainMenuItem";
import MultiMenu from "./MultiMenu";
import SavingsResultMenu from "./SavingsResultMenu";
import MenuHorizontalRule from "./MenuHorizontalRule";
import AlertIcon from "../Icons/AlertIcon";
import ReportsMenu from "./ReportsMenu";
import InsightIcon from "../Icons/InsightIcon";
import GitForkIcon from "../Icons/GitForkIcon";

const TO_URL = {
  multicluster: "/multicluster",
  analytics: "/multiCluster/analytics",
  insight: "/multiCluster/insight",
  report: "/multiCluster/reports",
};

const MultiClusterMenu = () => {
  const { currentCluster } = useMainContext();
  const [selectedClusterNamesHistory, setSelectedClusterNamesHistory] = useState<string[]>([]);

  useEffect(() => {
    if (currentCluster && !selectedClusterNamesHistory.includes(currentCluster)) {
      setSelectedClusterNamesHistory([...selectedClusterNamesHistory, currentCluster]);
    }
    if (selectedClusterNamesHistory.length > 0 && currentCluster) {
      toast.success(`Cluster changed successfully to ${currentCluster}`);
    }
  }, [currentCluster]);

  return (
    <div className="flex flex-col gap-3">
      <MenuHorizontalRule title="Multi Cluster" />
      <MainMenuItem icon={<GitForkIcon />} title="Clusters" to={TO_URL.multicluster} />
      <MainMenuItem title="Alerts" icon={<AlertIcon />} to="/multiCluster/alerts" />
      <ReportsMenu />
      {!HAS_MULTILEVEL_ANALYTICS && (
        <>
          <MainMenuItem icon={<InsightIcon />} title="Troubleshoot" to={TO_URL.insight} />
          <SavingsResultMenu isMultiCluster={true} />
        </>
      )}
      {HAS_MULTILEVEL_ANALYTICS && (
        <MultiMenu
          baseUrl={{ name: "Analytics", url: "/multiCluster/analytics/general", variant: "caption" }}
          urls={[
            { name: "General", url: "/multiCluster/analytics/general" },
            { name: "Troubleshoot", url: "/multiCluster/analytics/insight" },
          ]}
          title="Analytics"
          MainMenuItemSize={MainMenuItemSize.Small}
        />
      )}
    </div>
  );
};

export default MultiClusterMenu;
