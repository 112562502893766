import { useState } from "react";
import MultiSelectByQueryParams, { MultiSelectByQueryParamsProps } from "../MultiSelectByQueryParams";
import { KeyValueOptions } from "./BulkPolicyEditorToolbar";

interface KeyValue {
  key: string;
  value: string;
}

type KeyValueMultiSelectProps = Omit<MultiSelectByQueryParamsProps, "options"> & {
  keysToValues: KeyValue[];
};

export const KeyValueMultiSelect = ({
  keysToValues,
  queryKey,
  tooltipPrefix,
  hasVirtualizedList,
  hasLogicalAndOR,
  logicalOperatorQueryKey,
}: KeyValueMultiSelectProps) => {
  const [currentOption, setCurrentOption] = useState<KeyValueOptions>(KeyValueOptions.KeyValue);

  // We prepend the option with the enum value so the backend can filter based on the selected option
  const options =
    currentOption === KeyValueOptions.KeyValue
      ? keysToValues.map((keyValue) => `${keyValue.key}=${keyValue.value}`)
      : currentOption === KeyValueOptions.Key
      ? keysToValues.map((keyValue) => `<${KeyValueOptions.Key}>${keyValue.key}`)
      : keysToValues.map((keyValue) => `<${KeyValueOptions.Value}>${keyValue.value}`);

  const uniqueOptions = Array.from(new Set(options));

  const customSelectOptions = {
    options: Object.values(KeyValueOptions),
    setOption: (option: string) => setCurrentOption(option as KeyValueOptions),
    currentOption,
  };

  const modifyOptionText = (option: string) => {
    Object.values(KeyValueOptions).forEach((optionEnum) => {
      if (option.startsWith(`<${optionEnum}>`)) {
        option = option.slice(`<${optionEnum}>`.length);
      }
    });

    return option;
  };

  return (
    <MultiSelectByQueryParams
      options={uniqueOptions}
      queryKey={queryKey}
      tooltipPrefix={tooltipPrefix}
      hasVirtualizedList={hasVirtualizedList}
      hasLogicalAndOR={hasLogicalAndOR}
      logicalOperatorQueryKey={logicalOperatorQueryKey}
      customSelectOptions={customSelectOptions}
      modifyOptionText={modifyOptionText}
    />
  );
};
