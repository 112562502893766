import { IconProps } from "./utils/utils";

const PositiveIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM1.2966 10C1.2966 14.8068 5.19324 18.7034 10 18.7034C14.8068 18.7034 18.7034 14.8068 18.7034 10C18.7034 5.19324 14.8068 1.2966 10 1.2966C5.19324 1.2966 1.2966 5.19324 1.2966 10Z" />
      <path d="M14.2275 6.09761C12.4804 6.67108 10.2532 8.20476 8.19942 10.832L6.98581 9.48506C6.7991 9.27167 6.45236 9.27167 6.26565 9.48506L5.37211 10.4853C5.19874 10.6853 5.21207 10.9787 5.39878 11.1521L8.14608 13.7927C8.3728 14.0061 8.74622 13.9527 8.90625 13.6727C10.3733 11.0187 11.947 9.04496 14.6142 6.77777C14.9343 6.49771 14.6409 5.96425 14.2275 6.09761Z" />
    </svg>
  );
};

export default PositiveIcon;
