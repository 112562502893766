import { adjustThreshold, defaultSampleWindowOptions, getSampleWindowOptions } from "./alertsUtils";
import React from "react";

interface AlertTooltipTitleProps {
  data: {
    id: string | undefined;
    window: number | undefined;
    interval: number | undefined;
    threshold: number | undefined;
  };
}

const AlertTooltipTitle = ({ data }: AlertTooltipTitleProps) => {
  const timeOptions = getSampleWindowOptions(data.id);
  const window = Object.keys(timeOptions).find(
    (key) => timeOptions[key as keyof typeof defaultSampleWindowOptions] === data.window
  );
  const windowMessage = (
    <>
      The alert is calculated over a <b>window of {window}</b>.
    </>
  );
  const interval = Object.keys(timeOptions).find(
    (key) => timeOptions[key as keyof typeof defaultSampleWindowOptions] === data.interval
  );
  const intervalMessage = (
    <>
      This alert <b>runs every {interval}</b>.
    </>
  );
  const message = [];

  if (data?.id?.startsWith("ResourceQuota-")) {
    const resource = data.id.split("-")[1];
    message.push(
      <>
        ScaleOps notifies when a ResourceQuota object for <i>{resource}</i> is nearing it's limits.
        <br />
        The alert fires if the <b>usage is over {(data.threshold ?? 0) * 100}%</b> of the limit.
      </>
    );
  } else {
    switch (data?.id) {
      case "CpuThrottling":
        message.push(
          <>
            ScaleOps notifies upon CPU throttling events.
            <br />
            The alert fires if the <b>throttling is over {(data.threshold ?? 0) * 100}%</b> of CPU time.
          </>
        );
        break;
      case "NodeUtilization":
        message.push(
          <>
            ScaleOps notifies upon high node utilization (<b>over {(data.threshold ?? 0) * 100}%</b>).
          </>
        );
        break;
      case "OutOfMemory":
        message.push(
          <>
            ScaleOps notifies upon a high number of OOM events.
            <br />
            The alert is fired if there are <b>over {data.threshold} events</b>.
          </>
        );
        break;
      case "OverProvisioned":
        message.push(
          <>
            ScaleOps notifies upon a high number of over provisioned workloads <br />(
            <b>over {(data.threshold ?? 0) * 100}% of the cluster</b>).
          </>
        );
        break;
      case "PodFailedCreateEvent":
        message.push(
          <>
            ScaleOps notifies upon <b>more than {data.threshold} FailedCreate events</b> (pods failed to create).
          </>
        );
        break;
      case "UnderProvisioned":
        message.push(
          <>
            ScaleOps notifies upon a high number of under provisioned workloads
            <br />(<b>over {(data.threshold ?? 0) * 100}% of the cluster</b>).
          </>
        );
        break;
      case "WorkloadRequestIncrease":
        message.push(
          <>
            ScaleOps notifies upon workload request increase.
            <br />
            The alert fires if a workload's request increases by <b>at least {(data.threshold ?? 0) * 100}%</b>.
          </>
        );
        break;
      case "ScaleopsSystem: admissions":
      case "ScaleopsSystem: agent":
      case "ScaleopsSystem: dashboard":
      case "ScaleopsSystem: kube-state-metrics":
      case "ScaleopsSystem: prometheus-server":
      case "ScaleopsSystem: recommender":
      case "ScaleopsSystem: updater":
        {
          const threshold = (adjustThreshold(true, data.threshold) ?? 0) * 100;
          message.push(
            <span>
              ScaleOps notifies upon issues with the{" "}
              <i>
                <b>{data.id.replace("ScaleopsSystem: ", "")}</b>
              </i>{" "}
              pod.
              <br />
              The alert fires if the pod is unhealthy <b>more than {threshold}% of the time</b>.
            </span>
          );
        }
        break;
      default:
        return <></>;
    }
  }

  message.push(windowMessage);
  message.push(intervalMessage);
  return (
    <>
      {message.map((msg, index) => (
        <div key={index}>{msg}</div>
      ))}
    </>
  );
};

export default AlertTooltipTitle;
