import { Typography } from "@mui/material";
import React from "react";
import Tooltip from "../Tooltip";
import { WorkloadOverviewTooltipMessage } from "./Columns/GridColumnsUtils";

interface Props {
  entityName: string | number;
  workloadName?: string;
  namespace?: string;
  entityType?: string;
  disabled?: boolean;
  message?: string;
  cluster?: string;
}

const ExploreEntityTooltip: React.FC<Props> = ({
  entityName,
  workloadName,
  namespace,
  entityType,
  disabled,
  message = "Explore workload overview",
  cluster,
}) => {
  return (
    <Tooltip
      title={WorkloadOverviewTooltipMessage(
        namespace,
        workloadName ?? (entityName as string),
        entityType,
        message,
        cluster
      )}
      maxWidth={500}
      disabled={disabled}
      className="w-full"
    >
      <Typography
        variant="body2"
        fontWeight={500}
        data-testid="workload-name"
        sx={{ whiteSpace: "normal" }}
        className="line-clamp-3 break-words"
      >
        {entityName}
      </Typography>
    </Tooltip>
  );
};

export default ExploreEntityTooltip;
