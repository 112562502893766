import { useQuery } from "@tanstack/react-query";
import { GetNodeYaml, GetNodeYamlResponse } from "../../../api/fetcher";
import LogIcon from "../../../Icons/LogIcon";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import YamlViewer from "../../common/YamlViewer";

interface Props {
  nodeName: string | undefined;
  minHeight?: number;
}

const NodeYaml = ({ nodeName, minHeight }: Props) => {
  const { queryFn, queryKey } = GetNodeYaml();
  const { data, isLoading, error } = useQuery<GetNodeYamlResponse, Error>({
    queryKey: [queryKey, nodeName],
    queryFn: () => queryFn(nodeName ?? ""),
    enabled: !!nodeName,
    refetchInterval: 60 * 1000,
  });

  return (
    <div style={{ minHeight: minHeight }} className={"flex flex-col gap-5"}>
      <div className="flex border border-border rounded p-4 items-center gap-10">
        <LogIcon width={30} height={30} />
        <Typography variant="body2">
          <b>Node's yaml</b>
          <p>Explore node's full yaml data</p>
        </Typography>
      </div>
      {isLoading && (
        <div className="flex m-auto items-center justify-center h-full w-full">
          <CircularProgress />
        </div>
      )}
      {error && (
        <div className="flex m-auto items-center justify-center h-full w-full">
          Error while loading node's yaml: {error.message}
        </div>
      )}
      {!isLoading && (
        <div className={"border border-border rounded p-4"}>
          <YamlViewer yaml={data?.yaml ?? ""} maxLines={34} exportFileName={nodeName} />
        </div>
      )}
    </div>
  );
};

export default NodeYaml;
