import { AlertProps } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";
import { SCALEOPS_COLORS } from "../../../colors";
import ScaleopsIcon from "../../../Icons/ScaleopsIcon";
import WorkloadTooltip from "../../../utils/workloadTooltip";

export const isUnallocatedRow = (wlrow: components["schemas"]["UtilsWorkload"]) => {
  return wlrow.workloadName == "__unallocated__";
};

export const WorkloadOverviewTooltipMessage = (
  namespace?: string,
  workloadName?: string,
  type?: string,
  message = "Explore workload overview",
  cluster?: string
) => (
  <>
    <div className={"flex flex-col gap-1 w-[300px]"}>
      <WorkloadTooltip namespace={namespace} workloadName={workloadName} type={type} cluster={cluster} />
      <div className="flex gap-1 mt-3" style={{ color: SCALEOPS_COLORS.primary.purpleBlue }}>
        <ScaleopsIcon width={12} height={12} className="mt-[3px]" /> <b>{message}</b>
      </div>
    </div>
  </>
);

export const WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY = 1000;

export const getPercentageOfOptimizedReplicas = (
  params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>
) => (params.row.replicas ? Math.round((params.row?.optimizedReplicas ?? 0 / params.row.replicas) * 100) : 0);

export type SnackbarProps =
  | (Pick<AlertProps, "severity"> & {
      children: { message?: string };
    })
  | null;
