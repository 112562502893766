import { Typography } from "@mui/material";
import clsx from "clsx";

export enum HrType {
  Solid = "solid",
  Dashed = "dashed",
}

interface Props {
  title?: React.ReactNode;
  type?: HrType;
}

const MenuHorizontalRule = ({ title, type = HrType.Solid }: Props) => (
  <div className="relative h-4 w-full flex justify-center items-center">
    <hr className={clsx("border-text-lightBlack w-full", { "border-dashed": type === HrType.Dashed })} />
    {title && (
      <Typography
        variant="caption"
        className="text-white left-auto right-auto top-auto button-auto p-2.5 bg-mainMenuBackground absolute z-20"
      >
        {title}
      </Typography>
    )}
  </div>
);

export default MenuHorizontalRule;
