import { Typography } from "@mui/material";
import clsx from "clsx";
import prettyBytes from "pretty-bytes";
import { useEffect } from "react";
import { AxisDomain } from "recharts/types/util/types";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";
import InsightIcon from "../../../../Icons/InsightIcon";
import { GetTopKQueryKey } from "../../../../api/fetcher";
import { SCALEOPS_COLORS } from "../../../../colors";
import AccordionSection from "../../../../components/AccordionSection";
import useHpaOptimizationEnabled from "../../../../components/WorkloadStatusByNamespace/useHpaOptimizationEnabled";
import { cpuFormatValue, customNumberFormatter, getMemoryValue } from "../../../../utils/formatterUtils";
import { memoryDataParser, OverviewLinkType } from "../../../../utils/graphUtils";
import { getDisplayWorkloadKey } from "../../../../utils/namesUtils";
import { DEFAULT_OPTION, NUMBER_OF_ELEMENTS_QUERY_PARAM_NAME } from "../NumberOfElementsFilter";
import { AggregateBy, useGetAggregationParams } from "../SelectAggregationView";
import { PreBuiltViewGroups } from "../ViewGroups";
import { CORE_ELEMENT_CLASS_NAME, DateType } from "../utils";
import TopKFilters from "./TopKFilters";
import TopKMultiDotChart from "./TopKMultiDotChart";
import TopKMultiLineChart, { QUANTILE, SUM } from "./TopKMultiLineChart";
import useFreezeTooltip, { TooltipTrigger } from "./hooks/useFreezeTooltip";
import { FrozenTooltipType } from "./hooks/utils";
import { NodeStatus, NodeStatusKeys } from "./topKUtils";
import useHasResourceQuota from "./useHasResourceQuota";

//const GROUP_BY = "day";
const HAS_TOPK_FILTERS = true;
const ELEMENT_CLASSNAME = "w-[calc(50%-20px)]";

const PERCENTAGE_Y_AXIS_DOMAIN = [0, 100];
const RESOURCE_QUOTA = <span className="text-text-darkGray">(resource quota)</span>;
const GENERAL_SYNC_ID = "GENERAL_SYNC_ID";
const NODE_SYNC_ID = "NODE_SYNC_ID";
const RESOURCE_QUOTA_SYNC_ID = "RESOURCE_QUOTA_SYNC_ID";
const SCALEOPS_HEALTH_NUMBER_OF_ELEMENTS = 20;

const memoryFormatter = (value: string | number) => {
  value = Number(value) * 1024 * 1024;
  if (value === Infinity || value === -Infinity || Number.isNaN(Number(value))) {
    value = 0;
  }

  return prettyBytes(value, {
    bits: false,
    binary: true,
  });
};

interface Props {
  date: DateType;
  setDate: (date: DateType) => void;
  isMulticluster?: boolean;
  viewGroup: PreBuiltViewGroups;
}

enum graphs {
  NodeUtilization = "NodeUtilization",
  // OutOfMemory = "OutOfMemory",
  OomLimits = "OomLimits",
  OomNodePressure = "OomNodePressure",
  Liveness = "Liveness",
  CpuThrottling = "CpuThrottling",
  AutomatedWorkloads = "AutomatedWorkloads",
  ExpensiveAndWasteful = "ExpensiveAndWasteful",
  WastedCpuOrMemory = "WastedCpuOrMemory",
  UseSmartPolicy = "UseSmartPolicy",
  NotScalingDownReasons = "NotScalingDownReasons",
  InstanceInfo = "InstanceInfo",
  UpdateEvictions = "UpdateEvicions",
  NodeAllocations = "NodeAllocations",
  ResourceQuotas = "ResourceQuotas",
  NoisyNeighbors = "NoisyNeighbors",
  OverOrUnderProvisioned = "OverOrUnderProvisioned",
  Downtime = "Downtime",
  ScaleOpsUsage = "ScaleOpsUsage",
  ScaleOpsRequests = "ScaleOpsRequests",
  IssuesOverTime = "IssuesOverTime",
  ScaleOpsVersion = "ScaleOpsVersion",
}

const inViewGroup = (graph: graphs, viewGroup: PreBuiltViewGroups) => {
  switch (viewGroup) {
    case PreBuiltViewGroups.all:
      return true;
    case PreBuiltViewGroups.performance:
      return [
        // graphs.OutOfMemory,
        graphs.OomLimits,
        graphs.OomNodePressure,
        graphs.Liveness,
        graphs.CpuThrottling,
        graphs.UpdateEvictions,
        graphs.NoisyNeighbors,
        graphs.AutomatedWorkloads,
        graphs.NodeUtilization,
        // graphs.NodeAllocations,
        graphs.ResourceQuotas,
        graphs.OverOrUnderProvisioned,
        graphs.Downtime,
      ].includes(graph);
    case PreBuiltViewGroups.highlight:
      return [
        graphs.NodeUtilization,
        // graphs.OutOfMemory,
        graphs.OomLimits,
        graphs.OomNodePressure,
        graphs.Liveness,
        graphs.CpuThrottling,
        graphs.AutomatedWorkloads,
        graphs.ExpensiveAndWasteful,
      ].includes(graph);
    case PreBuiltViewGroups.cost:
      return [
        graphs.AutomatedWorkloads,
        graphs.ExpensiveAndWasteful,
        // graphs.WastedCpuOrMemory,
        graphs.NodeAllocations,
        graphs.NotScalingDownReasons,
        graphs.InstanceInfo,
        graphs.UseSmartPolicy,
      ].includes(graph);
    case PreBuiltViewGroups.scaleOps:
      return [graphs.ScaleOpsUsage, graphs.ScaleOpsRequests, graphs.IssuesOverTime, graphs.ScaleOpsVersion].includes(
        graph
      );
    default:
      return false;
  }
};

const formatGroupDescription = (viewGroup: PreBuiltViewGroups) => {
  switch (viewGroup) {
    case PreBuiltViewGroups.highlight:
      return `Compare your workloads liveness probes, cost, resources and automation using a comprehensive set of metrics.`;
    case PreBuiltViewGroups.cost:
      return `Analyze your workloads cost breakdown, expensive and wasteful namespaces along with automation insights.`;
    case PreBuiltViewGroups.performance:
      return `Examine your workloads performance issues using out of memory, liveness probe failures and node utilization metrics.`;
    case PreBuiltViewGroups.scaleOps:
      return `Analyze ScaleOps workloads usage, requests and issues over time.`;
    default:
      return "";
  }
};

const getLegendComponentName = (name: string | undefined, isMultiCluster = false) => {
  if (!name) return "";
  const splitName = name.split("/");
  const numberOfElements = splitName.length;
  const lastElement = splitName[numberOfElements - 1];

  return isMultiCluster ? `${splitName[0]}/${lastElement}` : lastElement;
};

const legendName = (name: string | undefined) => getLegendComponentName(name);

const multiClusterLegendName = (name: string | undefined) => getLegendComponentName(name, true);

const TopKContainer = ({ date, setDate, isMulticluster, viewGroup }: Props) => {
  const hasResourceQuota = useHasResourceQuota();
  const { aggregationView } = useGetAggregationParams();
  const enableHpaOptimization = useHpaOptimizationEnabled();

  const [numberOfElements] = useQueryParam(
    NUMBER_OF_ELEMENTS_QUERY_PARAM_NAME,
    withDefault(NumberParam, DEFAULT_OPTION)
  );

  const { tooltipTrigger, disabledZoom, updateActiveTooltips, setTooltipTrigger } = useFreezeTooltip({});

  useEffect(() => {
    setTooltipTrigger(TooltipTrigger.Hover);
  }, [aggregationView, viewGroup, date, numberOfElements]);

  let frozenAndClickableTab;

  switch (true) {
    case aggregationView === AggregateBy.Workloads:
      frozenAndClickableTab = FrozenTooltipType.FrozenAndClickable;
      break;
    case aggregationView === AggregateBy.Namespaces:
      frozenAndClickableTab = FrozenTooltipType.FrozenAndNotClickable;
      break;
    default:
      frozenAndClickableTab = FrozenTooltipType.Disabled;
  }

  return (
    <div className="w-full flex flex-col gap-8">
      <div className={clsx(CORE_ELEMENT_CLASS_NAME, "p-4 rounded-tl-none flex gap-3")}>
        <div className="flex items-center">
          <InsightIcon width={60} height={60} />
        </div>
        <div className="grow">
          <Typography variant="h6" fontWeight="bold">
            {viewGroup !== PreBuiltViewGroups.scaleOps && <>{viewGroup} View Profile</>}
            {viewGroup === PreBuiltViewGroups.scaleOps && <>ScaleOps Health View Profile</>}
          </Typography>
          <Typography variant="body2" className="text-text-lightBlack flex items-center gap-2">
            {formatGroupDescription(viewGroup)}
          </Typography>
        </div>
      </div>
      <div>
        <div className={clsx(CORE_ELEMENT_CLASS_NAME, "p-4 rounded-tl-none flex flex-wrap gap-[20px]")}>
          {HAS_TOPK_FILTERS && viewGroup !== PreBuiltViewGroups.scaleOps && (
            <TopKFilters isMultiCluster={isMulticluster} />
          )}
        </div>
        <div className={clsx(CORE_ELEMENT_CLASS_NAME, "p-4 rounded-tl-none flex flex-wrap gap-[20px]")}>
          {/*{inViewGroup(graphs.OutOfMemory, viewGroup) && (*/}
          {/*  <div className={ELEMENT_CLASSNAME}>*/}
          {/*    <TopKMultiLineChart*/}
          {/*      title="Out of memory"*/}
          {/*      queryParams={{*/}
          {/*        from: date.range ? undefined : date.from,*/}
          {/*        to: date.range ? undefined : date.to,*/}
          {/*        range: date.range,*/}
          {/*        queryKey:*/}
          {/*          selectedTab === AggregateBy.Workloads*/}
          {/*            ? GetTopKQueryKey.OOMOverTime*/}
          {/*            : GetTopKQueryKey.OOMOverTimeByNamespace,*/}
          {/*      }}*/}
          {/*      setDate={setDate}*/}
          {/*      isMulticluster={isMulticluster}*/}
          {/*      topK={numberOfElements}*/}
          {/*      tooltipTrigger={tooltipTrigger}*/}
          {/*      disabledZoom={disabledZoom}*/}
          {/*      frozenTooltipType={frozenAndClickableTab}*/}
          {/*      updateActiveTooltips={updateActiveTooltips}*/}
          {/*      dotted={true}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}
          {inViewGroup(graphs.OomLimits, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title="OOM limit"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.OOMOverTimeCauseLimits
                      : GetTopKQueryKey.OOMOverTimeByNamespaceCauseLimit,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                dotted={true}
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                hasStackedOffset
              />
            </div>
          )}
          {inViewGroup(graphs.OomNodePressure, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title="OOM node"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.OOMOverTimeCauseNodePressure
                      : GetTopKQueryKey.OOMOverTimeByNamespaceCauseNodePressure,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                dotted={true}
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                hasStackedOffset
              />
            </div>
          )}
          {inViewGroup(graphs.Liveness, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title="Liveness probe failures"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.Liveness
                      : GetTopKQueryKey.LivenessByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                dotted={true}
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                hasStackedOffset
              />
            </div>
          )}
          {inViewGroup(graphs.CpuThrottling, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title="CPU throttling events"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.Throttling
                      : GetTopKQueryKey.ThrottlingByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                dotted={true}
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                hasStackedOffset
              />
            </div>
          )}

          {inViewGroup(graphs.OverOrUnderProvisioned, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"CPU under-provisioned " + aggregationView.toLowerCase() + " on stressed nodes"}
                infoTooltip={
                  <>
                    <b>Sum</b> of <b>usage above request</b> for workloads running on <b>CPU stressed</b> nodes.
                    <br />
                    These workloads can lead to stressed nodes and cause performance issues to their neighbors.
                  </>
                }
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.CpuUnderProvisionedWorkloadsStressedNode
                      : GetTopKQueryKey.CpuUnderProvisionedNamespacesStressedNode,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
              />
            </div>
          )}
          {inViewGroup(graphs.OverOrUnderProvisioned, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"Memory under-provisioned " + aggregationView.toLowerCase() + " on stressed nodes"}
                infoTooltip={
                  <>
                    <b>Sum</b> of <b>usage above request</b> for workloads running on <b>Memory stressed</b> nodes.
                    <br />
                    These workloads can lead to stressed nodes and cause performance issues to their neighbors.
                  </>
                }
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.MemoryUnderProvisionedWorkloadsStressedNode
                      : GetTopKQueryKey.MemoryUnderProvisionedNamespacesStressedNode,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisFormatter={(x: string) => memoryDataParser(Number(x))}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
              />
            </div>
          )}
          {inViewGroup(graphs.AutomatedWorkloads, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiDotChart
                title={`User automation events by ${
                  aggregationView === AggregateBy.Workloads ? "workloads" : "namespaces"
                } `}
                infoTooltip={<>The events, when a user manually automated workloads</>}
                dotColor={SCALEOPS_COLORS.main.green}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.WorkloadHasAutomated
                      : GetTopKQueryKey.WorkloadHasAutomatedByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
              />
            </div>
          )}
          {inViewGroup(graphs.AutomatedWorkloads, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiDotChart
                title={`User un-automation events by ${
                  aggregationView === AggregateBy.Workloads ? "workloads" : "namespaces"
                }`}
                infoTooltip={<>The events when a user manually un-automated workloads</>}
                dotColor={SCALEOPS_COLORS.mainMenuBackgroundHover}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.WorkloadHasUnautomated
                      : GetTopKQueryKey.WorkloadHasUnUnautomatedByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
              />
            </div>
          )}
          {inViewGroup(graphs.ExpensiveAndWasteful, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={
                  <>
                    Expensive {aggregationView.toLowerCase()}{" "}
                    <span className="text-[12px] text-strongBorder">(monthly)</span>
                  </>
                }
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.ExpensiveWorkloads
                      : GetTopKQueryKey.ExpensiveNamespaces,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisFormatter={(value) => `$${value}`}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
              />
            </div>
          )}
          {inViewGroup(graphs.ExpensiveAndWasteful, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={
                  <>
                    Wasteful {aggregationView.toLowerCase()}{" "}
                    <span className="text-[12px] text-strongBorder"> (monthly)</span>
                  </>
                }
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? enableHpaOptimization
                        ? GetTopKQueryKey.WastefulWorkloadsWithReplicas
                        : GetTopKQueryKey.WastefulWorkloads
                      : enableHpaOptimization
                      ? GetTopKQueryKey.WastefulNamespacesWithReplicas
                      : GetTopKQueryKey.WastefulNamespaces,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisFormatter={(value) => `$${value}`}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
              />
            </div>
          )}
          {inViewGroup(graphs.WastedCpuOrMemory, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"Wasted CPU by " + aggregationView.toLowerCase()}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.WastedCpu
                      : GetTopKQueryKey.WastedCpuByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisFormatter={(value) => `${value}mil`}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
              />
            </div>
          )}
          {inViewGroup(graphs.WastedCpuOrMemory, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"Wasted memory by " + aggregationView.toLowerCase()}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.WastedMemory
                      : GetTopKQueryKey.WastedMemoryByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisFormatter={memoryFormatter}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
              />
            </div>
          )}
          {inViewGroup(graphs.UseSmartPolicy, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={`CPU waste due to unsuggested policies by ${aggregationView.toLowerCase()}`}
                queryParams={{
                  from: date?.from,
                  to: date?.to,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.UseSmartPolicyCpuDiff
                      : GetTopKQueryKey.UseSmartPolicyCpuDiffByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisFormatter={(value) => `${value}`}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
              />
            </div>
          )}
          {inViewGroup(graphs.UseSmartPolicy, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={`Memory waste due to unsuggested policies by ${aggregationView.toLowerCase()}`}
                queryParams={{
                  from: date?.from,
                  to: date?.to,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.UseSmartPolicyMemoryDiff
                      : GetTopKQueryKey.UseSmartPolicyMemoryDiffByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisFormatter={memoryFormatter}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
              />
            </div>
          )}
          {inViewGroup(graphs.Downtime, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"Downtime by " + aggregationView.toLowerCase()}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.DowntimeByWorkload
                      : GetTopKQueryKey.DowntimeByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                hasStackedOffset
              />
            </div>
          )}
          {inViewGroup(graphs.Downtime, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"Automated downtime by " + aggregationView.toLowerCase()}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.DowntimeByWorkloadAuto
                      : GetTopKQueryKey.DowntimeByNamespaceAuto,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                hasStackedOffset
              />
            </div>
          )}
          {inViewGroup(graphs.OverOrUnderProvisioned, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"CPU under-provisioned  " + aggregationView.toLowerCase()}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.CpuUnderProvisioned
                      : GetTopKQueryKey.CpuUnderProvisionedNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
              />
            </div>
          )}
          {inViewGroup(graphs.OverOrUnderProvisioned, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"Memory under-provisioned " + aggregationView.toLowerCase()}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.MemoryUnderProvisioned
                      : GetTopKQueryKey.MemoryUnderProvisionedNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
              />
            </div>
          )}
          {inViewGroup(graphs.UpdateEvictions, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title="ScaleOps optimization evictions"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.Evictions
                      : GetTopKQueryKey.EvictionsByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                dotted={true}
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                hasStackedOffset
              />
            </div>
          )}
          {inViewGroup(graphs.UpdateEvictions, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={
                  aggregationView.toLowerCase() === "workloads"
                    ? "Cluster workload disruptions"
                    : "Cluster namespace disruptions"
                }
                infoTooltip={
                  <>
                    This graph displays all cluster disruptions such as rollouts, node downs, preemptions, and API
                    evictions.
                  </>
                }
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    aggregationView === AggregateBy.Workloads
                      ? GetTopKQueryKey.ClusterEvictionsWorkloads
                      : GetTopKQueryKey.ClusterEvictionsNamespaces,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                dotted={true}
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
                keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
              />
            </div>
          )}
          {inViewGroup(graphs.ScaleOpsUsage, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"CPU usages"}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey: GetTopKQueryKey.ScaleOpsCPUUsage,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                YAxisDomain={[0, (dataMax: number) => dataMax * 1.25] as AxisDomain}
                YAxisFormatter={(value) => String(customNumberFormatter(Number(value)))}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                hasLegend={true}
                renderNameFunction={isMulticluster ? multiClusterLegendName : legendName}
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                topK={isMulticluster ? numberOfElements : SCALEOPS_HEALTH_NUMBER_OF_ELEMENTS}
              />
            </div>
          )}
          {inViewGroup(graphs.ScaleOpsUsage, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"Memory usages"}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey: GetTopKQueryKey.ScaleOpsMemoryUsage,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                YAxisFormatter={memoryFormatter}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                hasLegend={true}
                renderNameFunction={isMulticluster ? multiClusterLegendName : legendName}
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                topK={isMulticluster ? numberOfElements : SCALEOPS_HEALTH_NUMBER_OF_ELEMENTS}
              />
            </div>
          )}
          {inViewGroup(graphs.ScaleOpsRequests, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"CPU requests"}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey: GetTopKQueryKey.ScaleOpsCPURequests,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                YAxisFormatter={(value) => String(customNumberFormatter(Number(value)))}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                hasLegend={true}
                renderNameFunction={isMulticluster ? multiClusterLegendName : legendName}
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                topK={isMulticluster ? numberOfElements : SCALEOPS_HEALTH_NUMBER_OF_ELEMENTS}
              />
            </div>
          )}
          {inViewGroup(graphs.ScaleOpsRequests, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"Memory requests"}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey: GetTopKQueryKey.ScaleOpsMemoryRequests,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                YAxisFormatter={memoryFormatter}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                hasLegend={true}
                renderNameFunction={isMulticluster ? multiClusterLegendName : legendName}
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                topK={isMulticluster ? numberOfElements : SCALEOPS_HEALTH_NUMBER_OF_ELEMENTS}
              />
            </div>
          )}
          {inViewGroup(graphs.IssuesOverTime, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"Issues"}
                dotted
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey: GetTopKQueryKey.IssuesOverTime,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                YAxisDomain={[0, 2]}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                getParsedDataFixedValue={1}
                hasLegend={true}
                allowDecimals={false}
                renderNameFunction={(name) => {
                  if (!name) return "";
                  const splitName = name.split("/");
                  if (splitName.length < 3) return name;
                  const issueType = splitName[3];
                  const componentName = splitName[2].replace("scaleops-", "");
                  return issueType ? componentName + "/" + issueType : componentName;
                }}
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                topK={isMulticluster ? numberOfElements : SCALEOPS_HEALTH_NUMBER_OF_ELEMENTS}
              />
            </div>
          )}
          {inViewGroup(graphs.ScaleOpsVersion, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"Version"}
                dotted
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey: GetTopKQueryKey.Version,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                YAxisDomain={[0, 2]}
                YAxisType={"category"}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                hasLegend={true}
                YAxisFormatter={() => ""}
                yTickLine={false}
                frozenTooltipType={frozenAndClickableTab}
                syncId={GENERAL_SYNC_ID}
                topK={isMulticluster ? numberOfElements : SCALEOPS_HEALTH_NUMBER_OF_ELEMENTS}
              />
            </div>
          )}
        </div>
      </div>
      {viewGroup !== PreBuiltViewGroups.scaleOps && (
        <div className={clsx(CORE_ELEMENT_CLASS_NAME, "p-4 rounded-tl-none flex flex-wrap gap-[20px]")}>
          {inViewGroup(graphs.NodeUtilization, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title="Node CPU utilization (%)"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey: GetTopKQueryKey.NodeCpuUtilization,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
                YAxisFormatter={(value) => `${value}%`}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                getSecondaryValue={(label, _, secondaryValues) => {
                  const secondaryValue = secondaryValues?.[String(label)];
                  if (!secondaryValue) return null;
                  return <>| {cpuFormatValue(secondaryValue)}</>;
                }}
                enableCopyTextOnClick
                targetTooltipLink={OverviewLinkType.Node}
                tooltipValueTitle={"util | alloc"}
                frozenTooltipType={frozenAndClickableTab}
                syncId={NODE_SYNC_ID}
                topK={numberOfElements}
              />
            </div>
          )}
          {inViewGroup(graphs.NodeUtilization, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title="Node memory utilization (%)"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey: GetTopKQueryKey.NodeMemoryUtilization,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
                YAxisFormatter={(value) => `${value}%`}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                getSecondaryValue={(label, _, secondaryValues) => {
                  const secondaryValue = secondaryValues?.[String(label)];
                  if (!secondaryValue) return null;
                  return <>| {getMemoryValue(secondaryValue)}</>;
                }}
                enableCopyTextOnClick
                targetTooltipLink={OverviewLinkType.Node}
                tooltipValueTitle={"util | alloc"}
                frozenTooltipType={frozenAndClickableTab}
                syncId={NODE_SYNC_ID}
                topK={numberOfElements}
              />
            </div>
          )}
          {inViewGroup(graphs.NodeAllocations, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title="Node CPU allocation (%)"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey: GetTopKQueryKey.NodeCpuAllocationUsage,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
                YAxisFormatter={(value) => `${value}%`}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                targetTooltipLink={OverviewLinkType.Node}
                frozenTooltipType={frozenAndClickableTab}
                syncId={NODE_SYNC_ID}
              />
            </div>
          )}
          {inViewGroup(graphs.NodeAllocations, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title="Node memory allocation (%)"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey: GetTopKQueryKey.NodeMemoryAllocationUsage,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
                YAxisFormatter={(value) => `${value}%`}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                targetTooltipLink={OverviewLinkType.Node}
                frozenTooltipType={frozenAndClickableTab}
                syncId={NODE_SYNC_ID}
              />
            </div>
          )}

          {inViewGroup(graphs.NotScalingDownReasons, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title="Node not scaling down reasons"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey: GetTopKQueryKey.NodeNotScalingDown,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                renderNameFunction={(name: string | undefined) => {
                  switch (true) {
                    case name === SUM:
                      return "total";
                    case name?.includes(QUANTILE):
                      return `${String(name?.replace(QUANTILE, ""))}90th-percentile`;
                    case NodeStatusKeys.includes(name as keyof typeof NodeStatus):
                      return NodeStatus[name as keyof typeof NodeStatus];
                    default:
                      return name;
                  }
                }}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                frozenTooltipType={frozenAndClickableTab}
                syncId={NODE_SYNC_ID}
              />
            </div>
          )}
          {inViewGroup(graphs.InstanceInfo, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title="Nodes instance types"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey: GetTopKQueryKey.NodeInstanceType,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                frozenTooltipType={frozenAndClickableTab}
                syncId={NODE_SYNC_ID}
              />
            </div>
          )}
          {inViewGroup(graphs.InstanceInfo, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title="Nodes lifecycle"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey: GetTopKQueryKey.NodeUsageType,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick
                frozenTooltipType={frozenAndClickableTab}
                syncId={NODE_SYNC_ID}
              />
            </div>
          )}
        </div>
      )}

      {hasResourceQuota && inViewGroup(graphs.ResourceQuotas, viewGroup) && (
        <div className={clsx(CORE_ELEMENT_CLASS_NAME, "p-4 rounded-tl-none flex flex-wrap gap-[20px]")}>
          <AccordionSection title="Resource quotas" className="p-0" titleClassName="grow-1">
            <div className="flex w-full gap-[20px]">
              <div className={ELEMENT_CLASSNAME}>
                <TopKMultiLineChart
                  title={<>Namespace limitation by memory {RESOURCE_QUOTA} </>}
                  queryParams={{
                    from: date.range ? undefined : date.from,
                    to: date.range ? undefined : date.to,
                    range: date.range,
                    queryKey: GetTopKQueryKey.ResourceQuotaLimitedByNamespaceMemory,
                  }}
                  setDate={setDate}
                  isMulticluster={isMulticluster}
                  topK={numberOfElements}
                  YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
                  YAxisFormatter={(value) => `${value}%`}
                  updateActiveTooltips={updateActiveTooltips}
                  enableCopyTextOnClick
                  frozenTooltipType={frozenAndClickableTab}
                  syncId={RESOURCE_QUOTA_SYNC_ID}
                />
              </div>
              <div className={ELEMENT_CLASSNAME}>
                <TopKMultiLineChart
                  title={<>Namespace limitation by CPU {RESOURCE_QUOTA} </>}
                  queryParams={{
                    from: date.range ? undefined : date.from,
                    to: date.range ? undefined : date.to,
                    range: date.range,
                    queryKey: GetTopKQueryKey.ResourceQuotaLimitedByNamespaceCPU,
                  }}
                  setDate={setDate}
                  isMulticluster={isMulticluster}
                  topK={numberOfElements}
                  YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
                  YAxisFormatter={(value) => `${value}%`}
                  updateActiveTooltips={updateActiveTooltips}
                  enableCopyTextOnClick
                  frozenTooltipType={frozenAndClickableTab}
                  syncId={RESOURCE_QUOTA_SYNC_ID}
                />
              </div>
            </div>
            <div className="flex w-full gap-[20px]">
              <div className={ELEMENT_CLASSNAME}>
                <TopKMultiLineChart
                  title={<>Namespace limitation by pods {RESOURCE_QUOTA} </>}
                  queryParams={{
                    from: date.range ? undefined : date.from,
                    to: date.range ? undefined : date.to,
                    range: date.range,
                    queryKey: GetTopKQueryKey.ResourceQuotaLimitedByNamespacePods,
                  }}
                  setDate={setDate}
                  isMulticluster={isMulticluster}
                  topK={numberOfElements}
                  YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
                  YAxisFormatter={(value) => `${value}%`}
                  updateActiveTooltips={updateActiveTooltips}
                  enableCopyTextOnClick
                  frozenTooltipType={frozenAndClickableTab}
                  syncId={RESOURCE_QUOTA_SYNC_ID}
                />
              </div>
              <div className={ELEMENT_CLASSNAME}>
                <TopKMultiLineChart
                  title={<>Namespace limitation by replicasets {RESOURCE_QUOTA} </>}
                  queryParams={{
                    from: date.range ? undefined : date.from,
                    to: date.range ? undefined : date.to,
                    range: date.range,
                    queryKey: GetTopKQueryKey.ResourceQuotaLimitedByNamespaceReplicaSets,
                  }}
                  setDate={setDate}
                  isMulticluster={isMulticluster}
                  topK={numberOfElements}
                  YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
                  YAxisFormatter={(value) => `${value}%`}
                  updateActiveTooltips={updateActiveTooltips}
                  enableCopyTextOnClick
                  frozenTooltipType={frozenAndClickableTab}
                  syncId={RESOURCE_QUOTA_SYNC_ID}
                />
              </div>
            </div>
          </AccordionSection>
        </div>
      )}
    </div>
  );
};

export default TopKContainer;
