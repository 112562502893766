import { DialogContent, Drawer } from "@mui/material";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import DialogTitle from "./DialogTitle";
import SideMenu, { MenuItem } from "./SideMenu/SideMenu";
import { PolicyTabs } from "../pages/Policies/utils";
import Tab from "./Tab";
import { useEffect, useState } from "react";
import PolicyYAML from "../pages/Policies/PolicyYAML";
import { HPAPolicy } from "../api/fetcher";
import yaml from "js-yaml";
import { HPAMutationType } from "../pages/HPAPolicies/utils";

interface Props {
  title: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
  menuItems: MenuItem[];
  defaultSelectedMenuItemId: string;
  children: React.ReactNode;
  selectedMenuItemQueryParamsKey?: string;
  noPaddingForFirstLevel?: boolean;
  selectedPolicy: HPAPolicy | undefined;
  mutationType: HPAMutationType;
}

const RightDrawer: React.FC<Props> = ({
  title,
  onClose,
  isOpen,
  menuItems,
  defaultSelectedMenuItemId,
  children,
  selectedMenuItemQueryParamsKey = "selectedMenuItem",
  noPaddingForFirstLevel = true,
  selectedPolicy,
  mutationType,
}: Props) => {
  const [selectedMenuItem, setSelectedMenuItem] = useQueryParam(
    selectedMenuItemQueryParamsKey,
    withDefault(StringParam, defaultSelectedMenuItemId)
  );

  const [selectedTab, setSelectedTab] = useState(PolicyTabs.Policy);
  const [yamlString, setYamlString] = useState<string>("");
  const [isNewPolicy, setIsNewPolicy] = useState<boolean>(false);
  useEffect(() => {
    setIsNewPolicy(mutationType === HPAMutationType.CREATE);
  }, [mutationType]);

  useEffect(() => {
    if (!isNewPolicy) {
      const rowPolicyDataYaml = JSON.parse(JSON.stringify(selectedPolicy ?? "")) as HPAPolicy;
      if (rowPolicyDataYaml?.metadata?.managedFields) {
        delete rowPolicyDataYaml.metadata.managedFields;
      }
      const yamlString = yaml.dump(rowPolicyDataYaml);
      setYamlString(yamlString);
    }
    setSelectedTab(PolicyTabs.Policy);
  }, [selectedPolicy]);

  return (
    <Drawer
      onClose={onClose}
      open={isOpen}
      style={{ zIndex: 1300 }}
      className="overflow-y-auto flex flex-col top-0"
      anchor="right"
    >
      <DialogTitle title={title} />
      <DialogContent
        className="flex"
        sx={{
          padding: 0,
          overflowY: "hidden",
          minWidth: "37.5rem",
        }}
      >
        <div className="flex flex-col">
          <div className="flex pt-[10px] bg-background-chipHover gap-[3px]">
            {Object.entries(PolicyTabs)
              .filter(([, value]) => !(isNewPolicy && value === PolicyTabs.YAML))
              .map(([key, value]) => (
                <Tab
                  isSelected={selectedTab === value}
                  onClick={() => {
                    setSelectedTab(value);
                  }}
                  name={value}
                  dataTestId={`policy-${key}-tab`}
                  hasSelectedUnderline
                />
              ))}
          </div>
          {selectedTab === PolicyTabs.Policy && (
            <div className="flex">
              <SideMenu
                selectedMenuItem={selectedMenuItem}
                setSelectedMenuItem={setSelectedMenuItem}
                menuItems={menuItems}
                className="h-vh-100"
                noPaddingForFirstLevel={noPaddingForFirstLevel}
              />
              {children}
            </div>
          )}
          {selectedTab === PolicyTabs.YAML && (
            <div className="flex-grow w-[960px]">
              <PolicyYAML yamlString={yamlString} exportFileName={selectedPolicy?.metadata?.name ?? "policy"} />
            </div>
          )}
        </div>
      </DialogContent>
    </Drawer>
  );
};

export default RightDrawer;
