import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { components } from "../../../api/schema";
import { useMainContext } from "../../../MainContext";
import { currencyFormatter } from "../../../utils/formatterUtils";
import { getDataGridSx } from "../../../utils/styleUtils";
import { defaultTooltipTitle } from "../../autoscalers/NodesBreakdownTab";
import { getDisplayName } from "../../autoscalers/ScheduleBlockersGraph/utils";
import Tooltip from "../../Tooltip";
import NodeAction from "./NodeAction";

type ColParams = GridRenderCellParams<string, components["schemas"]["NodeGroupsBlockedNodeTableEntry"], string>;

interface CellTooltipProps {
  params: ColParams;
  children: React.ReactNode;
}
const CellTooltip = ({ params, children }: CellTooltipProps) => (
  <Tooltip maxWidth={770} title={defaultTooltipTitle(String(params.row.node))} className="max-w-full">
    {children}
  </Tooltip>
);

const getColumns = (): GridColDef[] => [
  {
    field: "node",
    headerName: "Node",
    flex: 2,
    renderCell: (params: ColParams) => {
      return (
        <CellTooltip params={params}>
          <p className="truncate">{params.value}</p>
        </CellTooltip>
      );
    },
  },
  {
    field: "savingsAvailable",
    width: 200,
    headerName: "Savings Available",
    align: "center",
    renderCell: (params: ColParams) => {
      const value = Number(params?.value ?? 0);
      const displayValue = currencyFormatter(value);
      return (
        <CellTooltip params={params}>
          <p className={clsx({ "text-guideline-darkGreen": value > 0 })}>{displayValue}</p>
        </CellTooltip>
      );
    },
  },
  {
    field: "blockedReason",
    flex: 1,
    minWidth: 450,
    headerName: "Blocked Reason",
    align: "center",
    renderCell: (params: ColParams) => {
      const displayValue = getDisplayName(String(params?.value ?? ""));
      return (
        <CellTooltip params={params}>
          <p className="truncate">{displayValue}</p>
        </CellTooltip>
      );
    },
  },
  {
    field: "category",
    flex: 1,
    minWidth: 280,
    headerName: "Action",
    align: "center",
    renderCell: (params: ColParams) => (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <NodeAction params={params.row} />
      </div>
    ),
  },
];

interface Props {
  data:
    | (components["schemas"]["NodeGroupsBlockedNodeTableEntry"] & { id: string; action: string | undefined })[]
    | undefined;
  isLoading: boolean;
}

const BlockedNodesTable = ({ data, isLoading }: Props) => {
  const { currentCluster } = useMainContext();
  const navigate = useNavigate();

  const onRowClick = (
    params: GridRowParams<
      components["schemas"]["NodeGroupsBlockedNodeTableEntry"] & { id: string; action: string | undefined }
    >
  ) => {
    navigate(`/nodes?currentClusterURLParam=${currentCluster ?? ""}&selectedNodeOverviewId=${params.row.node ?? ""}`);
  };

  return (
    <DataGrid
      sx={{
        ...getDataGridSx(),
      }}
      getCellClassName={() => "cursor-pointer"}
      onRowClick={onRowClick}
      rows={data || []}
      columns={getColumns()}
      autoHeight={true}
      rowHeight={65}
      loading={isLoading}
      disableSelectionOnClick
      hideFooter
    />
  );
};

export default BlockedNodesTable;
