import Typography, { TypographyProps } from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as React from "react";

export function Copyright(props: TypographyProps) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://scaleops.com/">
        ScaleOps
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
