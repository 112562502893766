import { SCALEOPS_COLORS } from "../../../../colors";
import DisableIcon from "../../../../Icons/DisableIcon";
import OnGoingOptimizationIcon from "../../../../Icons/OnGoingOptimizationIcon";
import PositiveIcon from "../../../../Icons/PositiveIcon";

export enum Event {
  podOptimizedCount = "podOptimizedCount",
  podEvictionCount = "podEvictionCount",
  optimizationBlockedCount = "optimizationBlockedCount",
  isAuto = "isAuto",
}

export const EventsDisplayName: Record<Event, string> = {
  [Event.podOptimizedCount]: "Pod optimized",
  [Event.podEvictionCount]: "Optimization evictions",
  [Event.optimizationBlockedCount]: "Delayed optimization eviction",
  [Event.isAuto]: "Automated",
};

export const GraphElements: Record<
  Event,
  { color: string; icon: JSX.Element; iconClassName?: string; isBlockedLineStyle?: boolean }
> = {
  [Event.podOptimizedCount]: {
    color: SCALEOPS_COLORS.guideline.darkGreen,
    icon: <PositiveIcon />,
  },
  [Event.podEvictionCount]: {
    color: SCALEOPS_COLORS.primary.purpleBlue,
    icon: <OnGoingOptimizationIcon />,
  },
  [Event.optimizationBlockedCount]: {
    color: SCALEOPS_COLORS.strongBorder,
    icon: <DisableIcon />,
    isBlockedLineStyle: true,
  },
  [Event.isAuto]: {
    color: SCALEOPS_COLORS.main.green,
    icon: <></>,
  },
};

export type EventChartPoint = {
  timestamp: number;
  podOptimizedCount?: number;
  podEvictionCount?: number;
  optimizationBlockedCount?: number;
  isAuto?: number;
  data?: {
    podOptimized?: {
      cpuBefore?: number;
      cpuAfter?: number;
      memoryBefore?: number;
      memoryAfter?: number;
    };
    optimizationBlocked?: {
      message?: string;
    };
    podEvictionCount?: number;
    podOptimizedCount?: number;
  };
};

export const exampleData: EventChartPoint[] = [
  {
    timestamp: 1733344113060,
  },
  {
    timestamp: 1733344245960,
    optimizationBlockedCount: 250,
    podEvictionCount: 3,
    podOptimizedCount: 123,
    data: {
      podOptimized: {
        cpuBefore: 20,
        cpuAfter: 2,
        memoryBefore: 10000000000000,
        memoryAfter: 10000000,
      },
      optimizationBlocked: {
        message: "example of blocked optimization message",
      },
    },
  },
  {
    timestamp: 1733344378860,
    optimizationBlockedCount: 11250,
    podEvictionCount: 1,
    podOptimizedCount: 123,
    data: {
      podOptimized: {
        cpuBefore: 20,
        cpuAfter: 2,
        memoryBefore: 10000000000000,
        memoryAfter: 10000000,
      },
      optimizationBlocked: {
        message: "example of blocked optimization message",
      },
    },
  },
  {
    timestamp: 1733344511760,
    optimizationBlockedCount: 250,
    podOptimizedCount: 12,
    data: {
      podOptimized: {
        cpuBefore: 20,
        cpuAfter: 2,
        memoryBefore: 10000000000000,
        memoryAfter: 10000000,
      },
      optimizationBlocked: {
        message: "example of blocked optimization message",
      },
    },
  },
  {
    timestamp: 1733344644660,
  },
  {
    timestamp: 1733344777560,
    optimizationBlockedCount: 250,
    podEvictionCount: 750,
    data: {
      podOptimized: {
        cpuBefore: 20,
        cpuAfter: 2,
        memoryBefore: 10000000000000,
        memoryAfter: 10000000,
      },
      optimizationBlocked: {
        message: "example of blocked optimization message",
      },
    },
  },
  {
    timestamp: 1733344910460,
    optimizationBlockedCount: 250,
    podEvictionCount: 11,
    podOptimizedCount: 123,
    data: {
      podOptimized: {
        cpuBefore: 20,
        cpuAfter: 2,
        memoryBefore: 10000000000000,
        memoryAfter: 10000000,
      },
      optimizationBlocked: {
        message: "example of blocked optimization message",
      },
    },
  },
  {
    timestamp: 1733345043360,
  },
  {
    timestamp: 1733345176260,
  },
];
