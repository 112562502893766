import { ArrayParam, BooleanParam, StringParam, useQueryParam } from "use-query-params";
import { components } from "../../api/schema";

export const NAMESPACES_QUERY_KEY = "namespaces";

interface Props {
  setSelectedRows?: React.Dispatch<React.SetStateAction<components["schemas"]["UtilsWorkload"][] | undefined>>;
}

const useClearWorkloadsOverviewFilters = ({ setSelectedRows }: Props) => {
  const [, setSearchTerms] = useQueryParam("searchTerms", ArrayParam);
  const [, setCurrentSearchTerm] = useQueryParam("currentSearchTerm", StringParam);
  const [, setWorkloadErrors] = useQueryParam("workloadErrors", ArrayParam);
  const [, setIsWorkloadErrorsExclude] = useQueryParam("isWorkloadErrorsExclude", BooleanParam);
  const [, setLogicalWorkloadErrors] = useQueryParam("logicalWorkloadErrors", StringParam);
  const [, setLogicalHpaPredictable] = useQueryParam("logicalHpaPredictable", StringParam);
  const [, setWastedResourcesParam] = useQueryParam("wastedResources", ArrayParam);
  const [, isWastedResourcesExclude] = useQueryParam("isWastedResourcesExclude", BooleanParam);
  const [, setWastedResourcesLogicalOperator] = useQueryParam("logicalWastedResources", StringParam);
  const [, setNamespacesParams] = useQueryParam(NAMESPACES_QUERY_KEY, ArrayParam);
  const [, setUpdateModeParams] = useQueryParam("updateMode", ArrayParam);
  const [, setIsNamespacesExclude] = useQueryParam("isNamespacesExclude", BooleanParam);
  const [, setIsUpdateModeExclude] = useQueryParam("isUpdateModeExclude", BooleanParam);
  const [, setNamespaceLogicalOperator] = useQueryParam("namespacesLogicalOperator", StringParam);
  const [, setPoliciesParams] = useQueryParam("policies", ArrayParam);
  const [, setPolicyParams] = useQueryParam("policy", ArrayParam);
  const [, setIsPolicyExclude] = useQueryParam("isPolicyExclude", BooleanParam);
  const [, setTypesParams] = useQueryParam("types", ArrayParam);
  const [, setIsTypesExclude] = useQueryParam("isTypesExclude", BooleanParam);
  const [, setCpuOverProvisionedParam] = useQueryParam("overProvisionedCpu", StringParam);
  const [, setCpuUnderProvisionedParam] = useQueryParam("underProvisionedCpu", StringParam);
  const [, setMemoryOverProvisionedParam] = useQueryParam("overProvisionedMemory", BooleanParam);
  const [, setMemoryUnderProvisionedParam] = useQueryParam("underProvisionedMemory", BooleanParam);
  const [, setOverProvisioned] = useQueryParam("overProvisioned", BooleanParam);
  const [, setUnderProvisioned] = useQueryParam("underProvisioned", BooleanParam);
  const [, setIsPdb] = useQueryParam("isPdb", BooleanParam);
  const [, setIsAutomated] = useQueryParam("isAutomated", BooleanParam);
  const [, setIsUnAutomated] = useQueryParam("isUnAutomated", BooleanParam);
  const [, setHasMinReplicasSavings] = useQueryParam("hasMinReplicasSavings", BooleanParam);
  const [, setHasHpaAutomatedPolicy] = useQueryParam("hasHpaAutomatedPolicy", BooleanParam);
  const [, setHasHpaUnAutomatedPolicy] = useQueryParam("hasHpaUnAutomatedPolicy", BooleanParam);
  const [, setHasHpa] = useQueryParam("hasHpa", BooleanParam);
  const [, setHasGPU] = useQueryParam("hasGPU", BooleanParam);
  const [, setMisconfiguredHpa] = useQueryParam("misconfiguredHpa", BooleanParam);
  const [, setPriorityClassNamesParams] = useQueryParam("priorityClassNames", ArrayParam);
  const [, setOptimizationGaps] = useQueryParam("optimizationGaps", ArrayParam);
  const [, setHpaOptimizationType] = useQueryParam("hpaOptimizationType", ArrayParam);
  const [, setSelectedLabelNames] = useQueryParam("labels", ArrayParam);
  const [, setSelectedAnnotationNames] = useQueryParam("annotations", ArrayParam);
  const [, setIsPriorityClassNamesExclude] = useQueryParam("isPriorityClassNamesExclude", BooleanParam);
  const [, setIsOptimizationGapsExclude] = useQueryParam("isOptimizationGapsExclude", BooleanParam);
  const [, setHpaPreictableExclude] = useQueryParam("isHpaPredictableExclude", BooleanParam);
  const [, SetLabelsParamsExclude] = useQueryParam("isLabelsExclude", BooleanParam);
  const [, setAnnotationsParamsExclude] = useQueryParam("isAnnotationExclude", BooleanParam);
  const [, setAnnotationLogicalOperator] = useQueryParam("annotationsLogicalOperator", StringParam);
  const [, setOutOfMemory] = useQueryParam("outOfMemory", BooleanParam);
  const [, setPendingConsumers] = useQueryParam("pendingConsumers", BooleanParam);
  const [, setAvailableSavingsFilter] = useQueryParam("availableSavingsFilter", StringParam);
  const [, setOverProvisionedFilter] = useQueryParam("overProvisionedFilter", StringParam);
  const [, setUnevictableTypes] = useQueryParam("unevictableTypes", ArrayParam);
  const [, setUnderProvisionedFilter] = useQueryParam("underProvisionedFilter", StringParam);
  const [, setNamespaceAnnotations] = useQueryParam("namespaceAnnotation", ArrayParam);
  const [, setNamespaceAnnotationsParamsExclude] = useQueryParam("isNamespaceAnnotationsExclude", BooleanParam);
  const [, setNamespaceAnnotationLogicalOperator] = useQueryParam("namespaceAnnotationsLogicalOperator", StringParam);
  const [, setSchedulerBlockers] = useQueryParam("schedulerBlockers", StringParam);

  const [, setIsSpotFriendly] = useQueryParam("spotFriendly", BooleanParam);
  const [, setIsSpotUnfriendly] = useQueryParam("spotUnfriendly", BooleanParam);
  const clearFilters = () => {
    setSearchTerms(undefined);
    setCurrentSearchTerm(undefined);
    setWorkloadErrors(undefined);
    setIsWorkloadErrorsExclude(undefined);
    setLogicalWorkloadErrors(undefined);
    setLogicalHpaPredictable(undefined);
    setWastedResourcesParam(undefined);
    isWastedResourcesExclude(undefined);
    setWastedResourcesLogicalOperator(undefined);
    setNamespacesParams(undefined);
    setUpdateModeParams(undefined);
    setIsNamespacesExclude(undefined);
    setIsUpdateModeExclude(undefined);
    setNamespaceLogicalOperator(undefined);
    setPoliciesParams(undefined);
    setPolicyParams(undefined);
    setIsPolicyExclude(undefined);
    setTypesParams(undefined);
    setIsTypesExclude(undefined);
    setCpuOverProvisionedParam(undefined);
    setCpuUnderProvisionedParam(undefined);
    setMemoryOverProvisionedParam(undefined);
    setMemoryUnderProvisionedParam(undefined);
    setOverProvisioned(undefined);
    setUnderProvisioned(undefined);
    setIsPdb(undefined);
    setIsAutomated(undefined);
    setIsUnAutomated(undefined);
    setHasMinReplicasSavings(undefined);
    setHasHpaAutomatedPolicy(undefined);
    setHasHpaUnAutomatedPolicy(undefined);
    setHasHpa(undefined);
    setHasGPU(undefined);
    setMisconfiguredHpa(undefined);
    setHpaOptimizationType(undefined);
    setPriorityClassNamesParams(undefined);
    setOptimizationGaps(undefined);
    setSelectedLabelNames(undefined);
    setSelectedAnnotationNames(undefined);
    setIsPriorityClassNamesExclude(undefined);
    setIsOptimizationGapsExclude(undefined);
    setHpaPreictableExclude(undefined);
    SetLabelsParamsExclude(undefined);
    setAnnotationsParamsExclude(undefined);
    setAnnotationLogicalOperator(undefined);
    setOutOfMemory(undefined);
    setPendingConsumers(undefined);
    setAvailableSavingsFilter(undefined);
    setOverProvisionedFilter(undefined);
    setUnderProvisionedFilter(undefined);
    setNamespaceAnnotations(undefined);
    setNamespaceAnnotationsParamsExclude(undefined);
    setNamespaceAnnotationLogicalOperator(undefined);
    setSchedulerBlockers(undefined);
    setSelectedRows && setSelectedRows([]);
    setIsSpotFriendly(undefined);
    setIsSpotUnfriendly(undefined);
    setUnevictableTypes(undefined);
  };

  return clearFilters;
};

export default useClearWorkloadsOverviewFilters;
