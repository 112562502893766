import YamlViewer from "../../components/common/YamlViewer";
import LogIcon from "../../Icons/LogIcon";
import { Typography } from "@mui/material";

interface Props {
  yamlString: string;
  exportFileName: string;
}

const PolicyYAML = ({ yamlString, exportFileName }: Props) => {
  return (
    <div className={"flex flex-col gap-5 p-5 h-full"}>
      <div className="flex border border-border rounded p-4 items-center gap-10">
        <LogIcon width={30} height={30} />
        <Typography variant="body2">
          <b>Policy's YAML</b>
          <p>Explore policy's full YAML data</p>
        </Typography>
      </div>
      <div className={"border border-border rounded p-4 h-full"}>
        <YamlViewer yaml={yamlString} exportFileName={exportFileName} />
      </div>
    </div>
  );
};

export default PolicyYAML;
