import {Checkbox, ListItemText, MenuItem, Paper, Typography} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import * as React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {ArrayParam, BooleanParam, StringParam, useQueryParam, withDefault} from "use-query-params";
import { useMainContext } from "../../MainContext";
import { GetAudits, GetAuditsResponse, GetClusterPersistentEvents, GetClusterPersistentEventsResponse} from "../../api/fetcher";
import { components } from "../../api/schema";
import { SCALEOPS_COLORS } from "../../colors";
import { DateType, getFromDate } from "../../pages/Analytics/AnalyticsV2/utils";
import { DEFAULT_DATE_TIME_FORMAT, TimeStandard } from "../../utils/formatterUtils";
import {getDataGridSx, HEADER_HEIGHT, PAGE_CONTENT_CLASS_NAME} from "../../utils/styleUtils";
import Input from "../Input";
import RangePicker, { RangeOptions } from "../RangePicker/RangePicker";
import Tooltip from "../Tooltip";
import clsx from "clsx";
import Tab, {TABS_CONTAINER_CLASS_NAME} from "../Tab";
import useGetNamespaces from "../WorkloadStatusByNamespace/OverviewHooks/useGetNamespaces";
import useGetLabels from "../WorkloadStatusByNamespace/OverviewHooks/useGetLabels";
import useGetAnnotations from "../WorkloadStatusByNamespace/OverviewHooks/useGetAnnotations";
import MultiSelectByQueryParams from "../MultiSelectByQueryParams";
import FilterChip, {FilterType} from "../FilterChip";

enum TabOptions {
  Audit = "User Events",
  PersistentClusterEvents = "ScaleOps Events",
}

const workloadTypes = {
  Deployment: "deployment",
  DaemonSet: "daemonset",
  StatefulSet: "statefulset",
  Job: "job",
  CronJob: "cronjob",
  KedaScaledJob: "kedascaledjob",
};

dayjs.extend(dayjsPluginUTC);

function AuditView() {
  const { currentCluster } = useMainContext();
  const { queryFn, queryKey } = GetAudits();

  const [audits, setAudits] = useState<GridRowsProp<components["schemas"]["MiddlewaresAuditObject"]>>([]);
  const [timeStandard] = useQueryParam("timeStandard", withDefault(StringParam, TimeStandard.LOCAL));
  const [showUser, setShowUser] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useQueryParam("searchTerm", StringParam);

  const [currentEpoch] = useState(Date.now());
  const [fromTime, setFromTime] = React.useState<number | undefined>(getFromDate(3, currentEpoch));
  const [toTime, setToTime] = React.useState<number | undefined>(currentEpoch);
  const [range, setRange] = useState<string | undefined>("3d");
  const date: DateType = {
    from: fromTime,
    to: toTime,
    range: range,
  };
  const setDate = (date: DateType) => {
    setFromTime(date.from);
    setToTime(date.to);
    setRange(date.range);
  };

  const { data, error, isLoading } = useQuery<GetAuditsResponse, Error>({
    queryKey: [queryKey, currentCluster, fromTime, toTime, range],
    queryFn: () =>
      queryFn({
        from: range ? undefined : fromTime,
        to: range ? undefined : toTime,
        range: range,
      }),
  });

  useEffect(() => {
    if (!data?.audits) {
      setAudits([]);
      return;
    }
    let audits = data.audits;
    if (searchTerm) {
      audits = filterAudits(data.audits, searchTerm);
    }
    setAudits(audits);
    setShowUser(!!data.audits.find((audit) => audit.user));
  }, [data, searchTerm]);

  const filterAudits = (audits: components["schemas"]["MiddlewaresAuditObject"][], searchTerm: string) => {
    return audits.filter((audit) => {
      if (!searchTerm) {
        return true;
      }
      if (!audit) {
        return true;
      }
      return (
        audit.operation?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        audit.message?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        audit.user?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        audit.startTimestamp?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  useEffect(() => {
    if (error) {
      setAudits([]);
      setShowUser(false);
      toast.error("Failed to fetch audits for cluster");
      console.log("Failed to fetch audits for cluster:", error);
    }
  }, [error]);

  const columns: GridColDef[] = [
    {
      field: "startTimestamp",
      headerName: "Start Time",
      renderCell: (params) => {
        return <div>{params.value ? dayjs(String(params.value)).format(DEFAULT_DATE_TIME_FORMAT) : null}</div>;
      },
      flex: 1,
      type: "string",
      align: "center",
      disableColumnMenu: true,
      sortable: false,
      maxWidth: 250,
    },
    {
      field: "operation",
      headerName: "Operation",
      flex: 1,
      type: "string",
      align: "center",
      disableColumnMenu: true,
      maxWidth: 320,
    },
    {
      field: "user",
      headerName: "User",
      flex: 1,
      type: "string",
      align: "center",
      disableColumnMenu: true,
      maxWidth: 250,
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      type: "string",
      align: "left",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["MiddlewaresAuditObject"], string>) => {
        // for bulk actions the message is in the format of "operation [workload1, workload2, ...]"
        const messages = params.value?.split("[");
        let workloads: string[] = [];
        if (messages?.length === 2) {
          workloads = messages[1].split("]")[0].split(",");
          if (workloads.length === 1) {
            // for old format, workloads are separated by space
            workloads = workloads[0].split(" ");
          }
        }
        const title = (
          <>
            <p>{messages?.[0]}</p>
            <ul>
              {workloads?.map((workload) => (
                <li key={workload} style={{ listStyleType: "disc", listStylePosition: "inside" }}>
                  {workload}
                </li>
              ))}
            </ul>
          </>
        );
        return (
          <Tooltip title={<div className="max-h-[190px] overflow-auto">{title}</div>} maxWidth={700} className={""}>
            <p className="cursor-default line-clamp-2 whitespace-normal max-w-full">{params.value}</p>
          </Tooltip>
        );
      },
    },
  ];

  let auditToDisplay = audits;
  if (timeStandard === TimeStandard.LOCAL) {
    auditToDisplay = audits.map((audit) => {
      return {
        ...audit,
        startTimestamp: audit.startTimestamp
          ? dayjs(`${audit.startTimestamp.replaceAll("-", "/")} UTC`).format(DEFAULT_DATE_TIME_FORMAT)
          : audit.startTimestamp,
        endTimestamp: audit.endTimestamp
          ? dayjs(`${audit.endTimestamp.replaceAll("-", "/")} UTC`).format(DEFAULT_DATE_TIME_FORMAT)
          : audit.endTimestamp,
      };
    });
  }

  return (
    <Paper sx={{ border: 'none', boxShadow: "none" }} className="absolute top-0 right-0 left-0" >
      <div className="p-8">
        <Typography variant="h6">User Events</Typography>
        <Typography className="max-w-[620px]" sx={{ fontSize: 12 }}>
          Review users operations such as policy attachment, automation enablement and more. Search for specific messages and filter by date.
        </Typography>
      </div>
      <div className="flex px-8 ">
        <div className="flex-grow flex items-end">
          <Input
            className="min-w-[150px]"
            borderColor={SCALEOPS_COLORS.black}
            placeholder="search..."
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            value={searchTerm ?? ""}
          />
        </div>
        <div className="flex gap-8 items-end">
          <RangePicker date={date} setDate={setDate} rangeOptions={RangeOptions.MEDIUM} />
        </div>
      </div>
      <div className="p-8">
        <DataGrid
          headerHeight={HEADER_HEIGHT}
          rowHeight={90}
          sx={{
            ...getDataGridSx(),
          }}
          key={auditToDisplay.toString()}
          hideFooter={true}
          columns={columns}
          rows={auditToDisplay}
          style={{ height: "100%" }}
          hideFooterPagination={true}
          autoHeight={true}
          initialState={{
            sorting: { sortModel: [{ sort: "desc", field: "startTimestamp" }] },
            columns: {
              columnVisibilityModel: {
                user: showUser,
              },
            },
          }}
          disableSelectionOnClick
          loading={isLoading}
        />
      </div>
    </Paper>
  );
}


interface PersistentEventsGrouped {
  /** Format: int64 */
  timestamp: number;
  kind: string;
  workloads: WorkloadData[];
}

interface WorkloadData {
    namespace: string;
    workloadName: string;
    workloadType: string;
    value: number;
}

const podEvictionKind = "podEviction";
const podOptimizedKind = "podOptimized";



function PersistentClusterEventsView() {
  const [persistentClusterEvents, setClusterEvents] = useState<GridRowsProp<components["schemas"]["WorkloadsEventGraphPoint"]>>([]);
  const { currentCluster } = useMainContext();
  const { queryFn, queryKey } = GetClusterPersistentEvents();
  const [searchTerm, setSearchTerm] = useQueryParam("searchTerm", StringParam);

  const [currentEpoch] = useState(Date.now());
  const [fromTime, setFromTime] = React.useState<number | undefined>(getFromDate(3, currentEpoch));
  const [toTime, setToTime] = React.useState<number | undefined>(currentEpoch);
  const [range, setRange] = useState<string | undefined>("3d");
  const [pageSize, setPageSize] = useState<number>(10);
  const date: DateType = {
    from: fromTime,
    to: toTime,
    range: range,
  };
  const setDate = (date: DateType) => {
    setFromTime(date.from);
    setToTime(date.to);
    setRange(date.range);
  };

  const namespaces = useGetNamespaces(false);
  const labels = useGetLabels(false);
  const annotations = useGetAnnotations(false);
  const [namespacesQueryParam] = useQueryParam("namespaces", ArrayParam);
  const [labelsQueryParam] = useQueryParam("labels", ArrayParam);
  const [annotationsQueryParam] = useQueryParam("annotations", ArrayParam);
  const [workloadTypesQueryParam] = useQueryParam("workloadTypes", ArrayParam);
  const [isNamespaceExclude] = useQueryParam("isNamespaceExclude", BooleanParam);
  const [isLabelExclude] = useQueryParam("isLabelExclude", BooleanParam);
  const [isAnnotationExclude] = useQueryParam("isAnnotationExclude", BooleanParam);
  const [isWorkloadTypeExclude] = useQueryParam("isWorkloadTypeExclude", BooleanParam);
  const [logicalLabel] = useQueryParam("logicalLabel", StringParam);
  const [logicalAnnotation] = useQueryParam("logicalAnnotation", StringParam);

  const { data, error, isLoading } = useQuery<GetClusterPersistentEventsResponse, Error>({
    queryKey: [queryKey, currentCluster, fromTime, toTime, range, searchTerm ,namespacesQueryParam, labelsQueryParam, annotationsQueryParam, workloadTypesQueryParam, isNamespaceExclude, isLabelExclude, isAnnotationExclude, isWorkloadTypeExclude, logicalLabel, logicalAnnotation,],
    queryFn: () =>
        queryFn({
          from: range ? undefined : fromTime,
          to: range ? undefined : toTime,
          range: range,
          namespaces: Array.isArray(namespacesQueryParam) ? namespacesQueryParam : [],
          labels: Array.isArray(labelsQueryParam) ? labelsQueryParam : [],
          annotations: Array.isArray(annotationsQueryParam) ? annotationsQueryParam : [],
          workloadTypes: Array.isArray(workloadTypesQueryParam) ? workloadTypesQueryParam : [],
          isNamespaceExclude: isNamespaceExclude ? isNamespaceExclude : false,
          isLabelExclude: isLabelExclude ? isLabelExclude : false,
          isAnnotationExclude: isAnnotationExclude ? isAnnotationExclude : false,
          isWorkloadTypeExclude: isWorkloadTypeExclude ? isWorkloadTypeExclude : false,
          logicalLabel: logicalLabel ? logicalLabel : "OR",
          logicalAnnotation: logicalAnnotation ? logicalAnnotation : "OR",
        }),
  });

  useEffect(() => {
    if (!data?.eventGraphPoints) {
      setClusterEvents([]);
      return;
    }
    let events = data.eventGraphPoints;
    if (searchTerm) {
      events = filterEvents(data.eventGraphPoints, searchTerm);
    }
    setClusterEvents(events);
  }, [data, searchTerm]);

  const filterEvents = (events: components["schemas"]["WorkloadsEventGraphPoint"][], searchTerm: string) => {
    return events.filter((event) => {
      if (!searchTerm) {
        return true;
      }
      if (!event) {
        return true;
      }
      return (
          event.namespace?.toLowerCase().includes(searchTerm?.toLowerCase() || '') ||
          event.workloadName?.toLowerCase().includes(searchTerm?.toLowerCase() || '')
      );
    });
  }

  useEffect(() => {
    if (error) {
      setClusterEvents([]);
      toast.error("Failed to fetch audits for cluster");
      console.log("Failed to fetch audits for cluster:", error);
    }
  }, [error]);

  const transformData = (events: Readonly<components["schemas"]["WorkloadsEventGraphPoint"][]>): PersistentEventsGrouped[] => {
    const result: Record<number, Record<string, PersistentEventsGrouped>> = {};

    events.forEach((item) => {
      const {timestamp, namespace, workloadName, workloadType, podEvictionCount, podOptimizedCount} = item;
      if ((timestamp===undefined || namespace===undefined || workloadName===undefined || workloadType===undefined) || (podEvictionCount===undefined && podOptimizedCount===undefined)) {
        return;
      }

      if (!result[timestamp]) {
        result[timestamp] = {};
      }

      if (podEvictionCount!==undefined) {
        if (!result[timestamp][podEvictionKind]) {
          result[timestamp][podEvictionKind] = {
            timestamp: timestamp,
            kind: podEvictionKind,
            workloads: [],
          }
        }
        result[timestamp][podEvictionKind].workloads.push({
          namespace: namespace,
          workloadName: workloadName,
          workloadType: workloadType,
          value: podEvictionCount,
        })
      }
      if (podOptimizedCount!==undefined) {
        if (!result[timestamp][podOptimizedKind]) {
          result[timestamp][podOptimizedKind] = {
            timestamp: timestamp,
            kind: podOptimizedKind,
            workloads: [],
          }
        }
        result[timestamp][podOptimizedKind].workloads.push({
          namespace: namespace,
          workloadName: workloadName,
          workloadType: workloadType,
          value: podOptimizedCount,
        })
      }
    })

    const flat: PersistentEventsGrouped[] = Object.values(result).flatMap((innerRecord) => Object.values(innerRecord))
    flat.sort((a, b) => {
      if (a.timestamp != b.timestamp) {
        return b.timestamp - a.timestamp;
      }
        return a.kind.localeCompare(b.kind);
    })
    return flat;
  }

  const columns: GridColDef[] = [
    {
      field: "timestamp",
      headerName: "timestamp",
      renderCell: (params) => {
        return <div>{params.value ? dayjs(Number(params.value) * 1000).format(DEFAULT_DATE_TIME_FORMAT) : null}</div>;
      },
      flex: 1,
      type: "string",
      align: "center",
      disableColumnMenu: true,
      sortable: false,
      maxWidth: 300,
    },
    {
      field: "workloadName",
      headerName: "Operation",
      flex: 1,
      type: "string",
      align: "center",
      disableColumnMenu: true,
      maxWidth: 500,
      renderCell: (params) => {
        const { kind } = params.row as PersistentEventsGrouped;
        const getMessageForKind = (kind: string) => {
          switch (kind) {
            case podEvictionKind:
              return "Eviction Optimization Triggered";
            case podOptimizedKind:
              return "Workload Optimized";
            }
        }
        return (
            <div>
              <div>{getMessageForKind(kind)}</div>
            </div>
        );
      },
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      type: "string",
      align: "left",
      disableColumnMenu: true,
      renderCell: (params) => {
        const { kind, workloads } = params.row as PersistentEventsGrouped;
        const workloadNames = workloads
            .sort((a, b) => {
              if (a.value != b.value) {
                return b.value - a.value
              }
              return `${a.namespace}/${a.workloadType}/${a.workloadName}`.localeCompare(`${b.namespace}/${b.workloadType}/${b.workloadName}`)
            })
            .map((workload) => `${workload.namespace}/${workload.workloadType}/${workload.workloadName}`)
        const title = (
            <>
              <ul>
                {workloadNames?.map((workload) => (
                    <li key={workload} style={{ listStyleType: "disc", listStylePosition: "inside" }}>
                      {workload}
                    </li>
                ))}
              </ul>
            </>
        );
        const getMessageForKind = (kind: string, length: number) => {
          const workloadString = length === 1 ? 'workload' : 'workloads';
          const verb = length === 1 ? 'was' : 'were';
          switch (kind) {
            case podEvictionKind:
              return `${workloadString} triggered eviction for optimization`;
            case podOptimizedKind:
              return `${workloadString} ${verb} optimized`;
          }
        }
        return (
            <Tooltip title={<div className="max-h-[190px] overflow-auto">{title}</div>} maxWidth={700} className={""}>
              <p className="cursor-default line-clamp-2 whitespace-normal max-w-full">{workloadNames.length} {getMessageForKind(kind, workloadNames.length)}: [{workloadNames.join(', ')}]</p>
            </Tooltip>
        );
      },
    }];

  const eventsToDisplay = transformData(persistentClusterEvents).sort((a, b) => a.timestamp - b.timestamp);

  return (
      <Paper sx={{ border: 'none', boxShadow: "none" }} className="absolute top-0 right-0 left-0" >
        <div className="p-8">
          <Typography variant="h6">ScaleOps Events</Typography>
          <Typography className="max-w-[620px]" sx={{fontSize: 12}}>
            Review ScaleOps ongoing operations such as pod eviction and pods optimization. Search for specific messages
            and filter by date and workload parameters.
          </Typography>
        </div>
        <div className="flex px-8 ">
          <div className="flex-grow flex gap-2 items-end">
            <Input
                className="min-w-[150px]"
                borderColor={SCALEOPS_COLORS.black}
                placeholder="search..."
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                value={searchTerm ?? ""}
            />
            <MultiSelectByQueryParams
                options={namespaces}
                queryKey="namespaces"
                excludeQueryKey="isNamespaceExclude"
                hasIsExclude
                hasVirtualizedList
                enableAddCustomValue
            />
            <MultiSelectByQueryParams
                options={labels}
                queryKey="labels"
                excludeQueryKey="isLabelExclude"
                hasVirtualizedList
                enableAddCustomValue
                hasLogicalAndOR
                logicalOperatorQueryKey="logicalLabel"
            />
            <MultiSelectByQueryParams
                options={annotations}
                queryKey="annotations"
                excludeQueryKey="isAnnotationExclude"
                hasVirtualizedList
                enableAddCustomValue
                hasLogicalAndOR
                logicalOperatorQueryKey="logicalAnnotation"
            />
            <MultiSelectByQueryParams
                options={Object.values(workloadTypes)}
                optionRenderFunction={(option, index, selected, dataTestId) => {
                  let displayName = Object.keys(workloadTypes).find(
                      (key) => workloadTypes[key as keyof typeof workloadTypes] === option
                  );

                  if (!displayName) displayName = option;

                  return (
                      <MenuItem
                          value={option}
                          key={`${index}-multi-select-option`}
                          data-testid={dataTestId ? `${dataTestId}-option-${index}` : undefined}
                          className="min-w-fit"
                      >
                        <div className="min-w-[235px] flex items-center">
                          <Checkbox checked={selected.indexOf(option) > -1} />
                          <ListItemText primary={displayName} />
                        </div>
                      </MenuItem>
                  );
                }}
                queryKey="workloadTypes"
                name="types"
                hasIsExclude
                excludeQueryKey="isWorkloadTypeExclude"
                hasVirtualizedList
                enableAddCustomValue
            />
          </div>
          <div className="flex gap-8 items-end">
            <RangePicker date={date} setDate={setDate} rangeOptions={RangeOptions.MEDIUM}/>
          </div>
        </div>
        <div className="flex px-8 pt-4 gap-2 flex-wrap">
          <FilterChip
              label="namespaces"
              filterType={FilterType.ARRAY}
              queryParam={"namespaces"}
              excludeQueryParam="isNamespaceExclude"
          />
          <FilterChip
              label="labels"
              filterType={FilterType.ARRAY}
              queryParam={"labels"}
              excludeQueryParam="isLabelExclude"
          />
          <FilterChip
              label="annotations"
              filterType={FilterType.ARRAY}
              queryParam={"annotations"}
              excludeQueryParam="isAnnotationExclude"
          />
          <FilterChip
              label="types"
              filterType={FilterType.ARRAY}
              queryParam={"workloadTypes"}
              excludeQueryParam="isWorkloadTypeExclude"
          />
        </div>
          <div className="px-8 pt-4 pb-8">
            <DataGrid
                headerHeight={HEADER_HEIGHT}
                rowHeight={90}
                sx={{
                  ...getDataGridSx(),
                }}
                key={eventsToDisplay.toString()}
                columns={columns}
                getRowId={(row: PersistentEventsGrouped) => row.timestamp && row.kind? `${row.timestamp.toString()}-${row.kind}` : ""}
                rows={eventsToDisplay}
                style={{height: "100%"}}
                autoHeight={true}
                initialState={{
                  sorting: {sortModel: [{sort: "desc", field: "timestamp"}]}
                }}
                disableSelectionOnClick
                loading={isLoading}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                onPageSizeChange={setPageSize}
                pagination
            />
          </div>
      </Paper>
  )
}

export default function Audit() {
  const [selectedTab, setSelectedTab] = React.useState<TabOptions>(TabOptions.Audit);

  return (
      <div className={clsx("relative mt-[36px] rounded-tl-none p-[50px]", PAGE_CONTENT_CLASS_NAME)}>
        <div className={clsx(TABS_CONTAINER_CLASS_NAME, "top-[-36px] left-0")}>
          {Object.entries(TabOptions).map(([key, value]) => {
            return (
                <Tab
                    key={key}
                    isSelected={selectedTab === value}
                    onClick={() => {
                      setSelectedTab(value);
                    }}
                    name={value}
                    dataTestId={`settings-tab-${key}-tab`}
                />
            );
          })}
        </div>
        {TabOptions.Audit === selectedTab && <AuditView />}
        {TabOptions.PersistentClusterEvents === selectedTab && <PersistentClusterEventsView/>}
      </div>
  );
}

