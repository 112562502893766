import { Typography } from "@mui/material";
import { HPAPolicy } from "../../../api/fetcher";
import CustomIcon from "../../../Icons/CustomIcon";
import LockIcon from "../../../Icons/LockIcon";
import { isBuiltInPolicy } from "../../../utils/policyUtils";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";

interface Props {
  selectedPolicy: HPAPolicy | undefined;
  createNewPolicyTitle: boolean;
}

const HPAPolicyTitle = ({ selectedPolicy, createNewPolicyTitle }: Props) => {
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const isCustomizedPolicy = !createNewPolicyTitle && !isBuiltInPolicy(selectedPolicy) && !isReadyOnlyFrontEnd;

  return (
    <div className="w-full flex items-center justify-between">
      <Typography variant="body2" className="flex items-center gap-1 grow">
        {!createNewPolicyTitle && !isCustomizedPolicy && <LockIcon />}
        <div className="flex items-center">
          {createNewPolicyTitle ? "Create new policy" : selectedPolicy?.metadata?.name}
        </div>
        {!createNewPolicyTitle && isBuiltInPolicy(selectedPolicy) && (
          <Typography
            variant="caption"
            className="bg-mainMenuSelected rounded-full px-2 text-black flex items-center justify-center gap-1"
          >
            <CustomIcon width={14} height={14} />
            Built in policy
          </Typography>
        )}
      </Typography>
    </div>
  );
};

export default HPAPolicyTitle;
