import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getDashboardByNamespace, GetDashboardByNamespaceResponse } from "../api/fetcher";
import CodeSnippet, { THEME } from "../components/CodeSnippet";
import Tooltip from "../components/Tooltip";
import useFilterQueryParams from "../components/WorkloadStatusByNamespace/useFilterQueryParams";
import AuditIcon from "../Icons/AuditIcon";
import BasicSettingsIcon from "../Icons/BasicSettingsIcon";
import DocsIcon from "../Icons/DocsIcon";
import FullLogo from "../Icons/FullLogo";
import InfoIcon from "../Icons/InfoIcon";
import InsightIcon from "../Icons/InsightIcon";
import LightingIcon from "../Icons/LightingIcon";
import LogoutIcon from "../Icons/LogoutIcon";
import PlainCubeIcon from "../Icons/PlainCubeIcon";
import RecommendationIcon from "../Icons/RecommendationIcon";
import ScaleopsIcon from "../Icons/ScaleopsIcon";
import { useMainContext } from "../MainContext";
import { HAS_MULTILEVEL_ANALYTICS } from "../pages/Analytics/AnalyticsV2/AnalyticsContainer";
import { GetConfig } from "../utils/ConfigHelper";
import { HAS_FREE_TRIAL_NOTIFICATION } from "../utils/developmentFeatureFlags";
import { FeatureEnabled } from "../utils/FeaturesHelper";
import { LINK_CLASS_NAME } from "../utils/styleUtils";
import { documentationUrl } from "../utils/urlUtils";
import useIsReadOnlyFrontEnd from "../utils/useIsReadyOnlyFrontEnd";
import FreeTrialNotification from "./FreeTrialNotification";
import HealthCheckStatus from "./HealthCheckStatus";
import MainMenuItem, { MainMenuItemSize } from "./MainMenuItem";
import MultiClusterMenu from "./MultiClusterMenu";
import MultiMenu from "./MultiMenu";
import MultiProductMenu from "./MultiProductMenu";
import SavingsResultMenu from "./SavingsResultMenu";
import SelectCluster from "./SelectCluster";
import UpgradeVersion from "./UpgradeVersion";
import UserRole from "./UserRole";
import useHpaOptimizationEnabled from "../components/WorkloadStatusByNamespace/useHpaOptimizationEnabled";
import MenuHorizontalRule from "./MenuHorizontalRule";

const HAS_QUICK_ACTION = false;
export const MAIN_MENU_WIDTH = 230;

const MainMenu = () => {
  const hasHpaOptimizationEnabled = useHpaOptimizationEnabled();
  const config = GetConfig();
  const version = FeatureEnabled("DemoVersion") ? "demo" : config.version || "";

  const { currentCluster } = useMainContext();
  const isReadOnlyFrontEnd = useIsReadOnlyFrontEnd();
  const filterQueryParams = useFilterQueryParams();
  const dashboardByNamespace = getDashboardByNamespace();
  const { data, isLoading, error } = useQuery<GetDashboardByNamespaceResponse, Error>({
    queryKey: [dashboardByNamespace.queryKey, filterQueryParams],
    queryFn: () => dashboardByNamespace.queryFn(filterQueryParams),
    enabled: !!Object.keys(filterQueryParams).length,
  });

  useEffect(() => {
    const sessionCurrentCluster = sessionStorage.getItem("currentCluster");
    if (sessionCurrentCluster !== currentCluster) {
      sessionStorage.setItem("currentCluster", currentCluster ?? "");
    }
  }, [currentCluster]);

  const logout = () => {
    localStorage.removeItem("__scaleops_token");
    window.location.href = "/login";
  };

  const readOnlyTooltip = (
    <div className="w-[366px] flex flex-col gap-2">
      You are in read-only mode. <br />
      Upgrade to default mode, by adding the following parameter to the upgrade command:
      <CodeSnippet codeSnippet={"--set readOnly=false"} theme={THEME.light} oneLiner={true} />
      <span>
        Visit our{" "}
        <a
          href={documentationUrl({
            path: "installation/advanced-configuration",
            token: config.token,
          })}
          target="_blank"
          rel="noreferrer"
          className={LINK_CLASS_NAME}
        >
          Install & Config docs
        </a>
        <br />
        <br />
        <span className="flex">
          <ScaleopsIcon />
          <span className="ml-2">
            {isLoading && <>Loading number of managed workloads...</>}
            {error && <>Error loading number of managed workloads</>}
            {data && (
              <>
                <strong>{data.totalNamespaceSummary?.automatedWorkloads}</strong> workloads optimized by ScaleOps
              </>
            )}
          </span>
        </span>
      </span>
    </div>
  );

  return (
    <div
      className={`fixed h-[100vh] p-[20px] bg-mainMenuBackground top-0 flex flex-col text-white overflow-y-auto scrollbar-thin scrollbar-thumb-primary-300`}
      style={{ zIndex: 999, width: MAIN_MENU_WIDTH }}
    >
      <NavLink to="/" id="logo" className="min-w-[159px] min-h-[33px]">
        <FullLogo />
      </NavLink>
      <div className="grow flex flex-col gap-3 mt-3">
        <div className="flex flex-col gap-3 mt-2">
          <div className="flex flex-col gap-2 mb-2">
            {HAS_FREE_TRIAL_NOTIFICATION && (
              <div className="mt-[-20px] mb-[16px]">
                <FreeTrialNotification />
              </div>
            )}
            <SelectCluster />
            <UserRole ssoName={config?.ssoConf?.provider} groups={config?.userGroups} name={config?.userName} />
            <HealthCheckStatus />
          </div>
          <MultiProductMenu hpaOptimizationEnabled={hasHpaOptimizationEnabled} />
          <MainMenuItem icon={<PlainCubeIcon />} title="Nodes" to="/nodes" />
          {/*<MainMenuItem icon={<AlertIcon />} title="Alerts" to="/alerts" />*/}
          {HAS_QUICK_ACTION && (
            <MainMenuItem icon={<LightingIcon />} title="Quick actions" to="/dashboards/insights/" />
          )}
          {!HAS_MULTILEVEL_ANALYTICS && (
            <>
              <MainMenuItem icon={<InsightIcon />} title="Troubleshoot" to="/dashboards/insight" />
              <SavingsResultMenu />
            </>
          )}

          {HAS_MULTILEVEL_ANALYTICS && (
            <MultiMenu
              baseUrl={{ name: "Analytics", url: "/dashboards/analytics/general" }}
              urls={[
                { name: "General", url: "/dashboards/analytics/general" },
                { name: "Troubleshoot", url: "/dashboards/analytics/insight" },
              ]}
              icon={<RecommendationIcon />}
              title="Analytics"
              MainMenuItemSize={MainMenuItemSize.Small}
            />
          )}
          <MainMenuItem icon={<AuditIcon />} title="Events" to="/audits" />
        </div>
        <MultiClusterMenu />
      </div>
      <div className="h-fit">
        <div className="py-2 flex flex-col gap-3">
          <MenuHorizontalRule />
          <MainMenuItem icon={<BasicSettingsIcon />} title="Settings" to="/settings" />
          <MainMenuItem
            icon={<DocsIcon />}
            title="Docs"
            to={documentationUrl({
              token: config.token,
            })}
            isExternalLink
          />
          {config?.ssoConf?.provider &&
            (config.ssoConf?.provider === "simpleAuth" ||
              config.ssoConf?.provider === "ldap" ||
              config.ssoConf?.provider === "token") && (
              <button
                className="flex justify-start items-center gap-2 rounded-lg px-4 py-1 text-white hover:bg-mainMenuBackgroundHover text-[14px]"
                onClick={logout}
                id="log-out"
              >
                <LogoutIcon width={17} height={17} className="ml-[2px]" />
                <span className="ml-[-2px]">Log out</span>
              </button>
            )}
          <div className={"flex flex-col gap-1"}>
            <div className="flex gap-2 items-center">
              <Tooltip title={version} className="truncate cursor-default w-fit max-w-[100%] pl-[17px]">
                <Typography variant="caption" fontWeight={400}>
                  {version}
                </Typography>
              </Tooltip>
              <UpgradeVersion />
            </div>
            <Typography variant="caption" className="flex gap-1 items-center pl-[17px] text-primary-lightPurpleBlue ">
              {isReadOnlyFrontEnd && (
                <>
                  Read-only
                  <Tooltip title={readOnlyTooltip} maxWidth={500}>
                    <InfoIcon height={11} width={11} />
                  </Tooltip>
                </>
              )}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainMenu;
