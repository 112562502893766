import { StringParam, useQueryParam } from "use-query-params";

const BlockedNodesFilters = () => {
  const [search, setSearch] = useQueryParam("blockedNodesSearchTerm", StringParam);

  return (
    <input
      placeholder="search..."
      value={search || ""}
      onChange={(event) => {
        setSearch(event.target.value);
      }}
      className="border border-border rounded-md px-2 py-1 focus:outline-none w-[250px]"
    />
  );
};

export default BlockedNodesFilters;
